#search{
  position: fixed;
  overflow: scroll;
  background-color: $tint-1;
  color: white;
  width: 100%;
  height: 100vh;
  top: 0; left: 0;
  z-index: 600;
  padding: 4rem 0 3rem;
  opacity: 1;
  transition: opacity .4s ease;

  .container{
    margin: auto !important;
  }
  .btn-dismiss{
    position: fixed;
    top: .75rem;
    right: .75rem;

    // Override the default way of loading the SVG to be able to change the color on hover.
    &::before {
      content: '';
    }
    &:hover {
      svg path {
        fill: $brand-primary;
      }
    }
  }

  h6{
    color: white;
  }


  .search-form{
    background-color: $tint-1;
    position: sticky;
    top: 0;
    z-index: 700;
    transition: all .5s ease;
    height: 70px;
    display: flex;
    align-items: center;
    margin-right: 1.25rem;
    @include media-breakpoint-up(md){
      margin-right: 0;
    }
    @include media-breakpoint-up(lg){
      top: .5rem;
    }
  }
  .form-full{
    height: calc(100vh - 8rem);
    margin-right: 0;
  }
  .input-group{
    position: relative;
    .form-control{
      @include media-breakpoint-up(sm){
        padding-left: 55px;
      }
    }
  }
  .input-group-addon{
    padding-right: 0;
    position: absolute;
    top: 6px;
  }

  .btn-white{
    background-color: $input-bg;
    border: 1px solid $tint-1;
    &:hover{
      background-color: $white !important;
      color: $brand-primary;
      border-color: $white;
    }
  }
  .form-control:focus ~ .input-group-btn{
    .btn-white{
      border-color: $white;
      background-color: $input-bg-focus;

    }
  }
}



#search-results{
  margin-top: 1rem;
  overflow: scroll;
  h6, .lead{
    color: white;
  }

}

.card-search{
  border-top: 1px solid rgba($white, .5);
  padding: .75rem 0;
  margin: 1rem 0;
  background-color: transparent;
  position: relative;
  transition: all .5s ease;
  opacity: .8;
  @include media-breakpoint-up(md){
    max-height: 200px;
    overflow: hidden;
  }
  *{
    color: white;
  }
  .badge{
    margin-bottom: .5rem;
    svg{
      margin-left: 8px;
    }
  }
  &:after{
    @include media-breakpoint-up(md){
      content:'';
      position: absolute;
      width: 100%;
      height: 40px;
      bottom: 0;
      left: 0;
      background-image: linear-gradient(transparent 0%, $tint-1 100%);
    }
  }

  &:hover{
    opacity: 1;
    border-color: $white;
    @include media-breakpoint-up(md){
      max-height: 1000px;
    }
  }
}

#search.fade-enter, #search.fade-leave-to, #search.fade-leave-active {
  opacity: 0;
}

#search.fade-enter-to, #search.fade-leave {
  opacity: 1;
}
