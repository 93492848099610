.matrix-block-image{
  margin: 1.5rem 0;
  .container{
    position: relative;
    padding-top: .75rem;
    padding-bottom: .75rem;
    &:before, &:after{
      content: '';
      position: absolute;
      top: 0;
      left: 50%;
      width: 140px;
      height: 1px;
      margin-left: -70px;
      background-color: $brand-secondary;
    }
    &:after{
      top: auto;
      bottom: 0;
    }
  }
  .img-wrapper{
    display: flex;
    align-items: center;
    justify-content: space-around;
    img{
      max-height: 700px;
      object-fit: contain;
      padding: .75rem 1rem;
    }
    .two-images{
      max-width: 50%;
    }
  }
}

.blockquote{
  margin: 1rem 0 2rem;
  padding: 0 1rem;
  .quote{
    color: $brand-secondary-light;
    text-transform: uppercase;
    font-weight: 800;
    font-size: 1.4rem;
    line-height: 1.3;
  }
  .label{
    color: $brand-secondary-light;
    p{
      margin: 0;
    }
  }
}


.matrix-block-quoteWithImage, .matrix-block-quote{
  margin: 2rem 0;
  .container{
    display: flex;
    align-items: center;
    justify-content: center;
    @include media-breakpoint-down(sm){
      flex-wrap:wrap;
    }

  }
  img{
    @include media-breakpoint-up(md){
      max-width: 275px;
      object-fit: contain;
    }
  }

  .blockquote{
    border-left: none;
    position: relative;
    background-color: $gray-lightest;
    padding: 3rem 2rem;
    margin-top: 0;
    @include media-breakpoint-up(md){
      margin-left: 2rem;
      margin-top: 1rem;
    }
    &::before{
      content: '\201C';
      color: $brand-primary;
      position: absolute;
      font-size: 16.5rem;
      line-height: 9.5rem;
      top: 0;
      left: 0;
    }
    &::after{
      content: '\201D';
      color: $brand-primary;
      position: absolute;
      font-size: 16.5rem;
      line-height: 14.5rem;
      right: 0;
    }
  }
}


.matrix-block-sectionTitle{
  margin-top: 4.5rem;
  &:first-child{
    margin-top: 0 ;
  }
}

.matrix-block-inlineImage{
  margin: 2rem 0;

  .img-wrapper{
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    justify-content: center;
    img{
      max-height: 700px;
      object-fit: contain;
      padding: .75rem .5rem;
      max-width: 100%;
      @include media-breakpoint-up(sm){
        max-width: 50%;
      }
      @include media-breakpoint-up(md){
        max-width: 33.3%;
      }
      @include media-breakpoint-up(lg){
        max-width: 25%;
      }
    }
    .image-3{
      padding: .75rem 1rem;
      @include media-breakpoint-up(lg){
        max-width: 33.3%;
      }

    }
    .image-2{
      padding: .75rem 1rem;
      @include media-breakpoint-up(md){
        max-width: 50%;
      }
      @include media-breakpoint-up(lg){
        max-width: 50%;
      }

    }
    .image-1{
      padding: .75rem 1rem;
      max-width: 100% !important;
    }
  }
  .figure-caption{
    position: relative;
    padding-top: 1rem;
    margin-top: 1rem;

    &:before{
      content: '';
      position: absolute;
      top: 0;
      left: 50%;
      width: 140px;
      height: 1px;
      margin-left: -70px;
      background-color: $brand-secondary;
    }
  }
}

.matrix-block-fullWidthImage{
  margin-top: 3rem;
  overflow: hidden;
  .hero-img{
    height: 360px;
  }
}

.matrix-block-text .page-text{
  font-size: 1.2rem;
  max-width: 940px;
}

.matrix-block-slideShow {
  position: relative;
}




.matrix-block-featuredPositions {
  margin-bottom: 1rem;
}
.job {
  border: 2px solid $brand-secondary-lighter;
  padding: 1rem;

  @include media-breakpoint-up(md) {
    padding: 1.25rem;
    margin: 0 .5rem;
    max-width: 18rem;
  }

  &:first-of-type {
    @include media-breakpoint-up(md) {
      margin-inline-start: 0
    }
  }

  &:last-of-type {
    @include media-breakpoint-up(md) {
      margin-inline-end: 0
    }
  }

  .job__category {
    color: $gray;
    font-size: $font-size-xs;
    text-transform: uppercase;
  }


  .job__title {
    color: $brand-secondary;
    font-size: $font-size-h4;
    margin-top: .5rem;
  }

  .job__location {
    color: $brand-secondary-dark;
    font-size: $font-size-h5;
  }
}
