.share-block{
  position: sticky;
  top: .5rem;
  // z-index: 300;
  h6{
    color: $brand-secondary-light;
  }
  .btn{
    padding: .5rem .8rem;
  }
  .btn-info{
    background-color: white;
  }
}
