.form-control {
  font-family: $font-family-sans-serif;
}

label {
  font-size: 15px;
  font-weight: 500;
}

.freeform {
  input.form-control,
  textarea,
  select {
    border: 1px solid black;
    color: black;

    &:focus {
      border: 1px solid black;
      color: black;
    }
  }
}

.custom-checkbox {
  .custom-control-description {
    transform: translateY(0.5rem);
    display: inline-block;
  }
}

.custom-control + .custom-control {
  @include media-breakpoint-down(xs) {
    margin-left: 0;
    margin-top: 0.75rem;
  }
}
