.col-card-shop {
  margin-bottom: 2rem;
  
  td,
  .card-title {
    color: #3E4B51;
  }

  .address {
    color: #434F55;
  }

  .table { margin-top: auto; }

  .table tr td {
    font-size: 15.5px;
    line-height: 18px;
    letter-spacing: 1.6px;
    text-transform: uppercase;
    padding: 1rem 0 .3rem;
    font-weight: bold;
  }

  .card-block {
    display: flex;
    flex-direction: column;
  }

  svg.ext-link {
    position: relative;
    top: 2px;
  }
}

.col-card-shop:hover {
  .card-location {
    background-color: #662448;
  }
	.card-text, .card-title, p, table, .address, .badge, .table tr td {
		color: white;
	}
  svg {
    path {
      fill: white;
    }
    g {
      stroke: white;
    }
  }

  .location-img {
    filter: grayscale(100%);
  }
}