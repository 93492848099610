.card-press{
  padding: .75rem;
  margin-bottom: 1.5rem;
  flex-wrap: wrap;
  transition: background .3s ease;

  .badge{
    margin-bottom: .5rem;
  }
  img{
    width: 100%;
    max-height: 45px;
    object-fit: contain;
    margin-bottom: .5rem;
    opacity: .5;
    filter: grayscale(100%);
    mix-blend-mode: multiply;
    transition: opacity .3s ease;
  }
  h6{
    color: $brand-secondary-light;
  }
  .badge, h5, h6{
    transition: color .3s ease;
  }
  .badge svg{
    transition: fill .3s ease;
  }

  .bottom-content{
    padding-top: .5rem;
    h6{
      margin-bottom: 0;
    }
  }
  &:hover{
    .badge, h5, h6{
      color: white;
    }
    .badge svg{
      fill: white;
    }
    img{
      opacity: 1;
    }
  }

}
.bottom-content{
  align-self: flex-end;
}

.card-press-wrapper{
  display: flex;
  flex-wrap: wrap;
}

@for $i from 1 through length($tints) {
  .card-press-wrapper:nth-child(#{length($tints)}n+#{$i}) {
    .card-press:hover{
      background-color: nth($tints, $i);
    }
  }
}
