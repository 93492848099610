body:not(.homepage).livestream {
    #header {
        min-height: 0;
        max-height: none;
        background-color: black;
        background-image: none;
        height: 0px;
        @media screen and (min-width: 1024px) {
            height: 100px;
        }
        .page-logo {
            position: absolute;
            right: 2.25rem;
            top: 0;
            img {
                height: 33.5px;
                width: 211px;
                @media screen and (min-width: 1024px) {
                    height: 44px;
                    width: 277px;
                }
            }
        }
    }
    p a { 
        color: #cf1974;
        background-image: none;
        word-break: break-word;
        @media screen and (min-width: 768px) {
            word-break: normal;
        }
        &:hover {
            color: #7F2255;
        }
    }
    #nav-main.nav-dark {
        position: absolute;
        @media screen and (min-width: 1024px) {
            position: static;
        }
        #logo-icon {
            .roof, .line  {
            fill: white;
            }
            > div {
                overflow: visible !important;
            }
            .logo-text {
                display: none;
                @media screen and (min-width: 1024px) {
                    display: block;
                }
            }
        }
    }
    #nav-utility {
        top: 11px;
        position: absolute;
        @media screen and (min-width: 1024px) {
            top: 30px;
        }
        h1 {
            font-family: futura, -apple-system,
            BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial;
            position: absolute;
            top: 40px;
            right: 40px;
            text-transform: uppercase;
            font-size: 1.3333rem;
            font-style: italic;
            font-weight: 600;
            color: #cf1974;
            letter-spacing: -1px;
        }
    }
    .page-wrapper {
        transition: none;
        margin-top: 0;
        &.scroll-down {
            transform: translate(0);
        }
        .container {
            @media (min-width: 1024px) {
                width: calc(100% - 4rem);
                margin-left: 30px;
            }
        }
        main {
            background-color: black;
            .iframe-container {
                margin-bottom: 1rem;
                position: relative;
                overflow: hidden;
                &:before {
                    display: block;
                    content: "";
                    width: 100%;
                    padding-bottom: ( 9 / 16 ) * 100%;
                }
                > iframe {
                    width: 100%;
                    height: 100%;
                    position: absolute;
                    top: 0;
                    right: 0;
                    bottom: 0;
                    left: 0;
                }
            }
            .iframes-container {
                border-bottom: 1px solid #232323;
                margin-bottom: 2rem;
                > div {
                    margin-bottom: 2rem;
                }
                @media screen and (min-width: 1024px) {
                    display: flex;
                    > div {
                        width: 50%;
                    }
                    > div:nth-of-type(odd) {
                        padding-right: 1rem;
                    }
                    > div:nth-of-type(even) {
                        padding-left: 1rem;
                    }
                }
                .iframe-container {
                    margin-bottom: 2rem;
                }
                h2 {
                    color: white;
                    font-size: 2rem;
                }
                .body {
                    color: #8D9A9F;
                    font-size: 1.75rem;
                }
            }
            .container {
                width: calc(100% - 4rem);
                margin-left: 30px;
                padding-right: 0;
                margin-right: 4rem;
                @media screen and (min-width: 768px) {
                    padding-left: 0.5rem;
                }
                @media screen and (min-width: 1024px) {
                    padding-left: 2.4rem;
                }
                .content {
                    display: flex;
                    flex-direction: column-reverse;
                    align-items: center;
                    @media screen and (min-width: 1024px) {
                        flex-direction: row;
                        align-items: flex-start;
                        justify-content: space-between;
                    }
                    .body {
                        max-width: 65ch;
                        color: #8D9A9F;
                        font-size: 1.5rem;
                        margin-right: 2rem;
                    }
                    .cta {
                        margin-bottom: 1rem;
                    }
                }
            }
        }
    }
}