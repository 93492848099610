.store-hour-changes {
  display: flex;
  padding: 0.6rem 0.4rem;
  margin-bottom: 1.5rem;

  .exclamation-icon {
    width: 44px;
    height: 44px;
    display: flex;
    flex-shrink: 0;
    justify-content: center;
    align-items: center;
    background-color: white;
    border-radius: 50%;
    color: $brand-primary;
    margin-right: 1rem;
    font-size: 2rem;
    font-weight: 600;
  }

  h6 {
    font-size: 0.6rem;
  }

  dl {
    .dl-inner {
      @include media-breakpoint-up(sm){
        display: flex;

      }
    }
    dt {
      width: 3.5rem;
    }

    dd {
      font-weight: 500;
    }
  }

  dl, p {
    font-size: 0.75rem;
  }

  h6, p {
    color: $brand-secondary-light;
  }

  p {
    margin-bottom: 0;
  }

}
