#advocacy-update{
  img.center, img.right{
    display: block;
    margin: 1rem auto;
  }
}

.block-related-topic{
  display: block;
  margin-top: 3rem;
  color: $body-color;
  &:hover{
    color: $brand-primary;
    h3{
      color: $brand-primary;
    }
  }
  img{
    margin-bottom: 1rem;
    @include media-breakpoint-up(md){
      margin-bottom: 0;
    }
  }
}
