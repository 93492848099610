.page-eyebrow{
  margin-bottom: 1rem;
  @include media-breakpoint-up(md){
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  h6{
    margin-top: .5rem;
    @include media-breakpoint-up(md){
      margin: 0;
    }
  }
}

.breadcrumb{
  margin-bottom: 0;
  .breadcrumb-item{
    display: inline-flex;
    align-items: center;
  }
  .breadcrumb-item, .badge{
    font-size: .9rem;
    text-transform: uppercase;
    letter-spacing: 1.6px;
    font-weight: 500;
    color: $brand-secondary;

    @include media-breakpoint-up(sm){
      font-size: 1.1rem;

    }

    .icon-wrapper{
      height:1.4rem;
      margin-right: .3rem;
    }
    svg{
      height: 1.3rem;
      width: 1.3rem;
      fill: $brand-secondary;
      transform: translateY(-1px);
    }
  }

  a{
    color: $tint-1;
    &:hover{
      color: $brand-primary;
    }
  }
}
