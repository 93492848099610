#header{
  height: 70vh;
  position: relative;
  background-image: $gradient-1;
  @include media-breakpoint-up(md){
    max-height: 700px;
    min-height: 500px;
  }
  .hero-img{
    position: absolute;
    width: 100%;
    height: 100%;
  }
  &:hover{
    .hero-img-front{
      @include media-breakpoint-up(lg){
        opacity: 1;
      }
    }
    .label{
      opacity: 1;
    }
  }

  .label{
    color: white;
    font-size: 12px;
    position: absolute;
    right: 1.3rem;
    bottom: 0;
    transform: rotate(90deg);
    transform-origin: right;
    opacity: 0;
    @media (min-width: 1480px){
      opacity: .6;
    }
  }
}

body.homepage #header {

  height: 0px;
  max-height: none;
  min-height: 0;
}

.hero-img{
  background-size: cover;
  background-repeat: no-repeat;
  background-position: 50% 50%;
  position: relative;
  // @supports (background-attachment: fixed) {
  //   background-attachment: fixed;
  // }
}

.no-touchevents {
  .hero-img {
    background-attachment: fixed;
  }
}

.hero-img-front{
  opacity: 0;
  transition: opacity .6s ease-in-out;
  &::before{
    content:'';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-image: linear-gradient(to bottom, rgba(black, .25) 50%, rgba(black, .6) 100%);
  }
}
.hero-img-back{
  filter: grayscale(100%);
  z-index: -1;
}

@keyframes grayToColor {
  0% {
    filter: grayscale(0);
  }

  100% {
    filter: grayscale(100%);

  }
}

#page-intro{
  position: relative;
  z-index: 1;
  padding-top: 0;
  & > .container{
    background-color: white;
    padding-top: 2.5rem;
  }

  & + #advocacy-app {
    margin-top: -3.5rem;
  }
}

.page-heading{
  position: relative;

  .hero-img{
    color: rgba($brand-primary, .7);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    padding-bottom: .75rem;
    margin-bottom: 2rem;
    z-index: 2;
    filter: grayscale(100%);
    &:before{
      background-image: inherit;
      background-size: inherit;
      background-repeat: inherit;
      background-attachment: inherit;
    }
  }

  h1{
    padding-bottom: .75rem;
    margin-bottom: 2rem;
    width: 100%;
    &:before {
      position: absolute;
      content: '';
      // top: 0;
      bottom: 0;
      left: 0;
      width: 100%;
      height: .5rem;

    }
  }
  h1.bar-above{
    &:before{
      top: -1.5rem;
      bottom: auto;
    }
  }


  .hero-bg{
    position: absolute;
    top: 0;
    left: 0;
    color: rgba($brand-primary, .7);
    z-index: 3;
    transition: all .6s ease-in-out;

    &:before{
      transition: all .6s ease-in-out;
      background-color: rgba($brand-primary, .7);
    }

    @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
      background-color: white;
    }

  }

}

.subtitle{
  color: $tint-1;
  transition: color .5s ease;
}

//LOAD IN ANIMATIONS

body.preload{

  .page-wrapper{
    transform:translate(0, 10rem);
  }
  .hero-img-front{
    opacity: 1;
  }
  .page-heading .hero-img{
    filter: grayscale(0);
  }
  .page-heading .hero-bg{
    color: rgba(black, .6);
    &:before{
      background-color: rgba(black, .6);
    }
  }
  .subtitle{
    color: $brand-secondary;
  }
}
body.preload.homepage{
  .page-wrapper{
    transform:translate(0, 0rem);
  }
}
