.card-update{
  margin-bottom: 1.5rem;
  flex-wrap: wrap;
  width: 100%;
  transition: background .3s ease;
  color: $body-color;
  img{
    opacity: 1;
    transition: opacity .3s ease;
  }

  &:hover{
    color: white;

    h3, h6, .badge {
      color: white;
      svg{
        fill: white;
      }
    }

    img{
      filter: grayscale(100%);
      mix-blend-mode: soft-light;
      opacity: .85;
    }

  }

  &.fromTheCeo{
    .card-block{
      display: flex;
      flex-wrap: wrap;
    }
    .badge{
      margin-top: .75rem;
      align-self: flex-end;
    }
  }
}

.card-update-alert{
  padding: .75rem;
  border: 2px solid $brand-primary;
  position: relative;

  &:after, &:before{
    content:'';
    width: 0;
    height: 0;
    border-right: 2.2rem solid $brand-secondary-lightest;
    border-bottom: 2.2rem solid transparent;
    position: absolute;
    top: -2px;
    right: -2px;
  }
  &:before{
    border-right: none;
    border-bottom: none;
    border-left: 2.2rem solid $brand-primary;
    border-top: 2.2rem solid transparent;
    transition: border .3s ease;
  }
  .badge{
    margin-bottom: .5rem;
    svg{
      width: 16px;
      height: 24px;
    }
  }
  h6{
    margin-top: 1rem;
    margin-bottom: 0;
  }


  &.actionAlert{
    color: $brand-primary;
    border-color: $brand-primary !important;
    h3, h6, .badge{
      color: $brand-primary;
      transition: color .3s ease;
    }
    .badge span{
      font-size: 24px;
      margin-right: 5px;
    }
    &:before{
      border-left-color: $brand-primary !important;
    }
    &:hover{
      background-color: $brand-primary !important;
      color: white;

      h3, h6, .badge {
        color: white;
      }
    }
  }

  &:hover{
    &:before{
      border-left-color: $white !important;
    }
  }
}

.card-update-wrapper{
  display: flex;
  flex-wrap: wrap;
}

@for $i from 1 through length($tints) {
  .card-update-wrapper:nth-child(#{length($tints)}n+#{$i}) {
    .card-update:hover{
      background-color: nth($tints, $i);
    }
    .card-update-alert{
      border-color: nth($tints, $i);
      &:before{
        border-left-color: nth($tints, $i);
      }
    }
  }
}

.legal-services .card-update-alert:after {
  border-right-color: #fff;
}