#newsletter {
  position: relative;
  background-color: $gray-darkest;
  padding: 1rem 0;
  color: white;
  -webkit-font-smoothing: antialiased;
  z-index: 11;

  h5, h6{
    color: white;
  }
  .newsletter-form{
    display: flex;
    @include media-breakpoint-down(lg){
      flex-wrap: wrap;
    }
  }
  h5{
    max-width: 800px;
  }
  .submit-wrapper{
    align-self: flex-end;
    margin-top: 1rem;
    @include media-breakpoint-up(xl){
      margin-top: 0;
      margin-left: auto;
      margin-right: 0;
    }
  }
  #click-zone{
    position: absolute;
    content:'';
    width: 100%;
    height: 200px;
    top: 0;
    z-index: 2;
    @include media-breakpoint-up(lg){
      height: 130px;

    }
  }
  .hidden-content{
    max-height: 0;
    transition: all .5s ease;
    overflow: hidden;
  }
  .btn{
    pointer-events: none;
  }
  &.open{
    pointer-events: none;
    .hidden-content{
      padding: .5rem 0;
      max-height: 1000px;
    }
    *{
      pointer-events: auto;
    }
    @include media-breakpoint-down(md) {
      #click-zone {
        display: none;
      }
    }
  }
}
