.category-nav.nav-pills {
  margin: 1rem 0;
  overflow-x: scroll;
  -webkit-overflow-scrolling: touch;

  .nav-link {
    border-bottom: 0.2rem solid transparent;
    padding: 0.5rem;
    margin-right: 0.3rem;
    display: flex;
    align-items: center;
    &:hover {
      background-color: white;
      .badge {
        color: $brand-secondary;
      }
      svg {
        fill: $brand-primary;
      }
    }
  }

  .badge {
    font-size: 0.8rem;
    letter-spacing: 1.6px;
  }
  .icon-wrapper {
    height: 26px;
    margin-right: 6px;
  }
  svg {
    height: 26px;
    width: 26px;
  }
  .active {
    border-color: $brand-primary;
    .badge {
      color: $brand-secondary;
    }
    svg {
      fill: $brand-primary;
    }
  }
}
