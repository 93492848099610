body.drawer-open {
	overflow: hidden;
}

.event-drawer {
	display: flex;
	flex-direction: column;
  position: fixed;
  top: 0;
  right: 0;
  height: 100vh;
  width: 100%;
  z-index: 999;
  color: white;
  overflow-y: scroll;
  transition: right 0.5s ease;


  @include media-breakpoint-up(md){
    width: 40vw;
    min-width: 650px;
  }

  .event-drawer-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 1rem;
    padding-right: .75rem;
    @include media-breakpoint-up(md){
      padding-left: 2rem;
    }

    h3{
      margin-bottom: 0;
    }
  }

  .card-event-date {
    padding: 0;

    .month, h5 {
      color: white;
    }

    h5 {
      line-height: 1rem;
    }
  }

  img {
    // max-height: 290px;
    width: 100%;
    // object-fit: cover;
  }

  .event-drawer-body {
		flex-grow: 1;
    padding: 1rem;
    position: relative;

    @include media-breakpoint-up(md){
      padding-left: 2rem;
      padding-right: 2rem;
    }

    .badge-wrapper {
			position: relative;
			margin-bottom: 1rem;
			max-width: 100%;
		
			.badge-inner-wrapper {
				display: flex;
				justify-content: flex-start;
				align-items: center;
				overflow-x: scroll;
				overflow-y: hidden;
				padding-right: 2rem;
				width: 100%;

				-ms-overflow-style: none;  /* IE and Edge */
				scrollbar-width: none;  /* Firefox */

				&::-webkit-scrollbar {
					display: none;
				}

				h6 {
					margin-bottom: 0;
				}

				.badge, h6 {
					margin-right: 1rem;
					color: white;
				}
			}
			.badge-bg-overflow {
				position: absolute;
				top: 0; right: 0; bottom: 0;
				width: 3rem;
				z-index: 1;
			}
		}
    p{
      max-width: 750px;
    }

    // h3 {
    //   font-family: $font-family-brand;
    //   font-size: 24px;
    //   margin-bottom: 0.2rem;
    // }

    h3, h5, h6 {
      color: white;
    }

    h6 {
      font-weight: 500;
    }
  }

  #btn-prev, #btn-next {
    position: absolute;
    bottom: 1rem;
    border: none;
    background-color: transparent;

    path {
      fill: white;
    }
    transition: transform .3s ease;

    &:hover{
      transform: scale(1.3);
    }
  }

  #btn-prev {
    left: 1rem;
    svg {
      transform: rotateZ(180deg);
    }
  }

  #btn-next {
    right: 1rem;
  }

	.btn-bg {
		min-height: calc(1rem + 45px);
		position: -webkit-sticky;
		position: sticky;
		bottom: 0; right: 0; left: 0;
		width: 100%;
	}
}


.drawer-wrapper{
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  right: 0;
  transition: right 0.5s;
  z-index: 800;
  svg{
    fill: white;
  }
}
.aside-bg{
  background-color: rgba($brand-secondary-lightest, .8);
  position: absolute;
  width: 100%;
  height: 100%;
  opacity: 1;
  transition: opacity .5s ease;
  z-index: 700;
}


.slide-enter, .slide-leave-to {
  aside{
    right: -100vw;

  }

  .aside-bg{
    opacity: 0;
  }
}

.slide-enter-to, .slide-leave {

  aside{
    right: 0;
  }

  .aside-bg{
    opacity: 1;
  }
}

#people-drawer .event-drawer{
  .btn-dismiss{
    position: absolute;
    right: .75rem;
    top: 1rem;
    z-index: 2;
  }
}
