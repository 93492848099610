.gm-style .gm-style-iw, .gm-style-iw  div {
	overflow: visible !important;
}
.gm-style .gm-style-iw-c {
	padding: 12px !important;
}
.info-window {
  font-family: $font-family-sans-serif;
  padding: 5px;

  .badge {
    margin-bottom: 0.3rem;
  }
  .btn-secondary {
    font-size: .6rem;
    padding: 0.3rem 0.5rem;
  }

	a.btn {
		display: inline-block;
	}
}
