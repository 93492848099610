.locations-map {
  opacity: 0;
  position: absolute !important;
  &.visible {
    position: relative !important;
    opacity: 1;
  }
}

.location-btn-wrapper {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-top: 3rem;

  h2 {
    margin-bottom: 0;
    margin-right: 1.5rem;
  }
  .btn{
    background-color: transparent;
    cursor: pointer;
    margin-right: 2px;
  }

  .btn.active, .btn:hover {
    background-color: white;
  }
}

#locations-app {
  .lead {
    margin-bottom: 1.5rem;
  }
}

.location-appointment {
  background-color: #ECEDED;
  color: #778185;
  font-weight: 500;
  padding: 1rem 2rem;
  margin-bottom: 1rem;
  > div {
    margin-bottom: 1rem;
  }
  .copy {
    line-height: 1.143;
    font-size: 28px;
  }
}

.appointment-modal {
  display: none;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 1000;
  background-color: rgba(40,43,44,0.95);
  height: 100vh;
  overflow: scroll;
  hr {
    height: 1px;
    background-color: rgba(255, 255, 255, 0.8);
  }
  .container {
    @media screen and (min-width: 1024px) {
      max-width: calc(100% - 12rem);
    }
    margin: 2rem auto;
    background-color: black;
    color: white;
    padding: 2rem;
    h2 {
      color: white;
    }
  }
  .appointment-intro {
    font-size: 28px;
    font-weight: 500;
    line-height: 1.128; 
    margin-bottom: 2rem;
  }
  .inputs {
    width: 100%;
    display: grid;
    grid-gap: 1rem;
    grid-template-columns: 1fr;
    @media screen and (min-width: 768px) {
      grid-template-columns: 1fr 1fr;
    }
    .form-group {
      margin-bottom: 0;
    }
    label {
      display: block;
    }
    input {
      width: 100%;
      color: white;
      background-color: #5F5F5F;
      border: none;
      padding: 0.5rem 1rem;
      &[name="message[Location]"] {
        background: transparent;
        font-size: 28px;
        font-weight: 500;
        padding: 0;
      }
    }
    select {
      margin-bottom: 1rem;
      width: 100%;
      padding: 0.5rem 0;
      option {
        background-color: #5F5F5F;
        color: white;
      }
    }
    button {
      margin-top: 1rem;
      margin-bottom: 2rem;
    }
  }
  .close {
    width: 32px;
    height: 32px;
    border: 1px solid white;
    span {
      position: relative;
      top: -8px;
      left: 3px;
      @media screen and (min-width: 1180px) {
        top: -9px;
        left: 2px;
      }
      @media screen and (min-width: 1440px) {
        top: -13px;
        left: 1px;
      }
    }
  }
  .additional-info {
    max-width: 75ch;
  }
  input#preferredLocation {
    // honeypot field
    display: none;
  }
  .vdp-datepicker__calendar {
    background-color: #5F5F5F;
    span.cell:not(.blank):not(.disabled):hover {
      background-color: #CF1974;
      border: 1px solid #CF1974;
    }
  }
  .vdp-datepicker {
    position: relative;
    div:first-of-type::after {
      content: "\f073";
      font: normal normal normal 20px FontAwesome;
      position: absolute;
      right: 17px;
      top: 13px;
    }
  }
  .vdp-datepicker input[type="text"]{
    &::placeholder {
      color: white;
      font-family: "GTWalsheim", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif;
      font-weight: 500;
    }
  }
  .vs__actions {
    display: none;
  }
  .vs__search {
    display: none;
  }
  .vs__selected-options {
    background-color: #5F5F5F;
    padding: 0.5rem 1rem;
  }
  .vs__dropdown-menu {
    list-style-type: none;
    padding-left: 1rem;
    li {
      padding: 0.25rem 0;
    }
  }
  .v-select {
    position: relative;
    .vs__selected-options{
      &::after {
        content: "\f0d7";
        font: normal normal normal 20px FontAwesome;
        position: absolute;
        right: 17px;
        top: 14px;;
      }
    }
    &[name="preferredTime"] .vs__selected-options::after {
      content: "\f017";
    }
    > ul {
      position: absolute;
      left: 0;
      right: 0;
      padding-left: 0;
      li {
        background-color: #5f5f5f;
        padding-left: 1rem;
        &:hover {
          background-color: #CF1974;
        }
      }
    }
  }
}

body.appointment-modal-open {
  position: relative;
  overflow: hidden;
  .page-wrapper, #page-intro .row > *, #page-intro > .container, #page-intro {
    position: static;
  }
  .appointment-modal {
    display: block;
  }
}

.confirmation-modal {
  .confirmation-message {
    font-size: 1.4rem;
    padding-bottom: 28px;
    line-height: 1.143;
  }
  div p {
    font-size: 1rem;
    margin-bottom: 28px;
    line-height: 1.4;
  }
  h6 {
    color: white;
    font-size: 1rem;
  }
}

.appointment-modal {
  .preferred-date {
    .v-select .vs__selected-options:after {
      top: 13px;
    }
  }
  
  .preferred-time {
    .v-select .vs__selected-options:after {
      top: 12px;
    }
  }
  
  .preferred-price-range {
    .v-select .vs__selected-options:after {
      top: 13px;
    }
  }
}