
.event-card {

	.details-event {
		display: flex;

		.card-text {
			margin-bottom: 0;
		}
		.event-date {
			padding-top: .5rem;
			padding-left: 0;

			text-align: center;

		}
		.event-column {

		  display: flex;
		  flex-direction: column;
		  padding-top: 3rem;

		}

		.card-title {
			margin-bottom: .5rem;
			margin-top: .5rem;
		}

	}

	img {
		width: 393px;
		height: 300px;
	}

}