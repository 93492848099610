.split-callout-wrapper {
  display: flex;
  justify-content: flex-start;
}

.no-cssfilters {

  .callout .callout-img {
    opacity: 0.3;
  }
}

.callout{
  min-height: 360px;
  position: relative;
  background-color: $callout-bg;
  display: block;
  overflow: hidden;
  transition: background .5s ease;
  z-index: 11;

  .callout-img{
    position: absolute;
    height: 100%;
    width: 100%;
    left: 0;
    top: 0;
    background-size: cover;
    background-repeat: no-repeat;
    filter: grayscale(100%);
    mix-blend-mode: multiply;
    opacity: .85;
    transform: scale(1);
    transition: all .8s ease;
  }
  .container{
    padding-top: 2rem;
    padding-bottom: 2rem;
    height: 100px; // this allows 'align-items: center' to work in IE11 (the height is overridden by the min-height below)
    min-height: 360px;
    display: flex;
    flex-wrap: wrap;
    align-items: center;

    .row {
      width: 100%;
    }
  }
  h3{
    color: white;
    -webkit-font-smoothing: antialiased;
  }

  .lead, .lead p{
    color: white;
    -webkit-font-smoothing: antialiased;
    font-size: 1.2rem;
  }
  @media (min-width: 1600px){
    .callout-content{
      padding-left: 2.2rem;
    }
  }
  .btn-secondary{
    transition: all .5s ease;
  }
  &:hover{
    background-color: darken($callout-bg, 10%);
    .callout-img{
      transform:scale(1.03);
    }
    .btn-secondary{
      background-color: $brand-primary;
      color: white;
    }
  }
}
