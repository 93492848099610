.section-homepage {
  height: 100vh;
  background-image: linear-gradient(to bottom, $tint-2 0%, $tint-1 100%);
  position: relative;
  -webkit-font-smoothing: antialiased;
  padding: 0;

  .info {
    position: relative;
    bottom: 0;
    left: 0;
    padding: 1.5rem;
    height: 100%;
    display: flex;
    align-items: flex-end;
    transition: opacity .3s ease;
    z-index: 5;
    @include media-breakpoint-up(md) {
      padding: 2.5rem;
    }
    @include media-breakpoint-down(sm) {
      opacity: 1 !important;
      text-shadow: 2px 2px 10px rgba(black, .3);
    }

    &.fadeOut {
      opacity: 0;
    }

    .info-content {
      max-width: 650px;
      position: relative;
      z-index: 21;
    }
  }
  h2 {
    color: white;
    font-size: 3rem;
    font-weight: 800;
    @include media-breakpoint-up(md) {
      font-size: 4rem;
    }
  }

  .lead p {
    color: white;
  }
}

.homepage-img {
  width: 100%;
  height: 100vh;
  background-size: cover;
  background-position: 50% 50%;
  background-repeat: no-repeat;
  top: 0;
  left: 0;

  &.background {
    position: fixed;
    transform: translateZ(0);
    will-change: opacity;

    &::after {
      content: '';
      position: absolute;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      background-color: rgba(black, .2);
      @include media-breakpoint-down(sm) {
        background-color: rgba(black, .35);
      }
    }
  }

  &.person {
    position: fixed;
    opacity: 0;
    z-index: 5;
    pointer-events: none;
    transform: translateZ(0);
    will-change: opacity;

    &#person1 {
      opacity: 1;
    }
    @include media-breakpoint-down(sm) {
      display: none;
    }
  }
}

.homepage-img1 {
  z-index: 5;
}
.homepage-img2 {
  z-index: 4;
}
.homepage-img3 {
  z-index: 3;
}
.homepage-img4 {
  z-index: 2;
}
.homepage-img5 {
  z-index: 1;
}
.homepage-img6 {
  z-index: 0;
}

.homepage #nav-main {
  z-index: 10;
}

// #backgroundOverlay {
//   position: fixed;
//   top: 0;
//   bottom: 0;
//   left: 0;
//   right: 0;
//   background-image: $gradient-1;
//   opacity: .8;
//   z-index: 7;
//   @include media-breakpoint-down(sm){
//     opacity: .7;
//   }
// }
