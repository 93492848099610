@font-face {
  font-family: 'GTWalsheim';
  src: url('../fonts/GT-Walsheim-Regular.woff') format('woff');
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: 'GTWalsheim';
  src: url('../fonts/GT-Walsheim-Regular-Oblique.woff') format('woff');
  font-weight: 400;
  font-style: italic;
}

@font-face {
  font-family: 'GTWalsheim';
  src: url('../fonts/GT-Walsheim-Medium.woff') format('woff');
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: 'GTWalsheim';
  src: url('../fonts/GT-Walsheim-Medium-Oblique.woff') format('woff');
  font-weight: 500;
  font-style: italic;
}

@font-face {
  font-family: 'GTWalsheim';
  src: url('../fonts/GT-Walsheim-Bold.woff') format('woff');
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: 'GTWalsheim';
  src: url('../fonts/GT-Walsheim-Bold-Oblique.woff') format('woff');
  font-weight: 600;
  font-style: italic;;
}

@font-face {
	font-family: 'Domaine';
	src: url('../fonts/domaine-display-condensed-web-semibold.eot') format('eot');
	src: url('../fonts/domaine-display-condensed-web-semibold.woff2') format('woff2'),
		 url('../fonts/domaine-display-condensed-web-semibold.woff') format('woff');
	font-weight: 400;
	font-style: normal;
}

@font-face {
	font-family: 'Domaine';
	src: url('../fonts/domaine-display-condensed-web-semibold-italic.eot') format('eot');
	src: url('../fonts/domaine-display-condensed-web-semibold-italic.woff2') format('woff2'),
		 url('../fonts/domaine-display-condensed-web-semibold-italic.woff') format('woff');
	font-weight: 400;
	font-style: italic;
}
