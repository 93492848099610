.button {
  -webkit-font-smoothing: antialiased;
  background-color: $brand-primary;
  border: 0;
  color: $white;
  display: inline-block;
  font-size: .8rem;
  font-weight: 600;
  letter-spacing: 1.6px;
  padding: .25rem .5rem;
  text-transform: uppercase;
  transition: all .3s ease;

  &:hover {
    color: $white;
    background-color: $tint-2;
  }
}
