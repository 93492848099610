.event-detail-featured-img {
  position: relative;
  border: 1px solid $brand-secondary-lightest;

  .event-img-date {
    position: absolute;
    bottom: 0;
    width: 4rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    h5, h6 {
      color: white;
    }

    h5 {
      margin-bottom: 0;
    }
    h6 {
      margin-bottom: 0.2rem;
    }
  }
}


#event-details {

  padding-top: 0;

  .icon-wrapper {
    svg {
      path {
        fill: $tint-2;
      }
    }
  }
  .event-content{
    .share-wrapper{
      margin-top: .5rem;
      padding-right: 1.5rem;

      // this is needed for .event-content-inner to maintain its width when .share-block becomes fixed:
      width: 89px;
    }
    @include media-breakpoint-down(sm){
      margin-bottom: 1rem;

    }
  }
}

.event-meta-wrapper {
  .location-link {
    display: block;
    margin: 0.5rem 0;

    h5 {
      background-image: linear-gradient($brand-secondary-lighter 0%, $brand-secondary-lighter 100%);
      background-repeat: repeat-x;
      background-size: 1px 1px;
      background-position: 0 85%;
      display: inline;
    }
  }

  address, h6 {
    color: $brand-secondary-light;
  }

  h6 {
    margin-top: 1.5rem;
  }

  .btn-directions {
    font-size: 0.55rem;
    padding: 0.3rem 0.9rem;
  }

  .get-directions {
    color: #222;
    transition: all .3s ease;
    background-image: linear-gradient(#606A6E 0%, #606A6E 100%);
    background-repeat: repeat-x;
    background-size: 1px 1px;
    background-position: 0 90%;
    &:hover {
      color: #3E4B51;
      background-image: linear-gradient(#CF1974 0%, #CF1974 100%);
    }
  }

  .hashtag {
    .image-container {
      position: relative;
      img {
        width: 100%;
        height: auto;
      }
      .instagram-source {
        position: absolute;
        bottom: 0;
        right: 0;
        left: 0;
        background-color: rgba(0,0,0,0.5);
        padding: 0.5rem;
        color: white;
        font-size: 0.75rem;
      }
    }
    .copy {
      background-color: rgba(242,213,213,0.5);
      padding: 1rem;
      h5 a {
        text-decoration: underline;
        font-size: 1.6rem;
        color: #004E59;
      }
      p {
        margin-bottom: 0.5rem;
      }
    }
  }
}

.address {
  font-family: $font-family-sans-serif;
  font-size: 0.75rem;
  font-weight: 500;
  color: $brand-secondary-light;
  margin-bottom: 0.5rem;
}

#calendar-app.upcoming-events {
  padding-top: 3rem;
  position: relative;
  z-index: 1;

  .btn-secondary {
    margin-top: 2.5rem;
    display: flex;
    justify-content: space-around;
    align-items: center;
    width: 225px;
    padding: 0.6rem 0.75rem;

    svg {
      path {
        fill: $brand-primary;
        transition: fill 0.3s ease-in-out;
      }
    }

    &:hover {
      svg {
        path {
          fill: white;
        }
      }
    }
  }
}

.event-content-inner {
  @include media-breakpoint-up(lg){
    width: calc(100% - 88px);
  }
}

.related-events {
  .event {
    display: block;
    color: #222;
    transition: all .3s ease;
    background-image: linear-gradient(#CF1974 0%, #CF1974 100%);
    background-repeat: repeat-x;
    background-size: 1px 1px;
    background-position: 0 100%;
    padding-bottom: 0.5rem;
    &:hover {
      background-image: linear-gradient(#606A6E 0%, #606A6E 100%);
      color: #3E4B51;
    }
  }
  .time-category {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
}

.venue-reviews {
  display: grid;
  grid-gap: 2rem;
  @media (min-width: 1024px) {
    grid-gap: 1rem;
  }
  grid-template-columns: 1fr 1fr;
  > * {
    width: 66.66666%;
    @media (min-width: 1024px) {
      width: 100%;
    }
  }
}

.venue-resources {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 2rem;
  @media (min-width: 1024px) {
    display: block;
  }
  figure {
    position: relative;
    width: 66.66666%;
    @media (min-width: 1024px) {
      width: calc(50% - 0.5rem);
    }
    margin: 0 0 0.5rem;
  }
  figure:before {
    content: '';
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    width: 100%;
    height: 100%;
    background-color: transparent;
    transition: background .5s ease;
  }
  a:hover figure:before {
    background-color: rgba(75,39,60,0.7);
  }
  .resource {
    margin-bottom: 1rem;
    img {
      width: 100%;
      height: auto;
    }
    h5 span {
      color: #222;
      transition: all .3s ease;
      background-image: linear-gradient(#606A6E 0%, #606A6E 100%);
      background-repeat: repeat-x;
      background-size: 1px 1px;
      background-position: 0 90%;
    }
    &:hover {
      h5 span {
        color: #3E4B51;
        background-image: linear-gradient(#CF1974 0%, #CF1974 100%);
      }
    }
  }
}

.private-events-main {
  font-size: 1.4rem;
  line-height: 1.14;
  .matrix-block-text {
    font-size: 1rem;
    line-height: 1.4;
  }
  .matrix-block-blockquote {
    blockquote {
      border: none;
      margin-top: 2rem;
      margin-bottom: 2rem;
      .quote {
        color: #004E59;
        font-size: 1.6rem;
        font-family: 'Domaine';
        text-transform: none;
        line-height: 1.125;
        p {
          margin-bottom: 0;
        }
      }
    }
    .attribution {
      font-size: 1.4rem;
      color: #004E59;
      margin-bottom: 2rem;
      span {
        padding-left: 5rem;
        @media (min-width: 1024px) {
          padding-left: 0;
        }
      }
    }
  }
  .hr-container {
    display: flex;
    justify-content: center;
    width: 100%;
  }
  hr {
    width: 135px;
    border-top-color: #3E4B51;
  }

  .matrix-block-images {
    hr {
      margin: 3.5rem 0;
    }
    img {
      width: 100%;
      height: auto;
    }
    .caption {
      text-align: center;
      color: #004E59;
      font-size: 0.75rem;
      line-height: 1.2;
      margin-top: 2rem;
    }
    .images-container {
      display: flex;
      justify-content: center;
      flex-wrap: wrap;
      margin: -1rem -0.5rem 0;
      > div {
        padding: 1rem 0.5rem 0rem;
        width: 33%;
        position: relative;
        a {
          background-position: center center;
          background-size: cover;
          display: block;
          position: relative;
          &:before {
            content: "";
            position: absolute;
            top: 0;
            left: 0;
            bottom: 0;
            right: 0;
            width: 100%;
            height: 100%;
            background-color: transparent;
            transition: background ease 0.5s;
          }
          &:hover::before {
            background-color:  rgba(242,213,213,0.5);
          }
          &:after {
            content: "";
            display: block;
            padding-bottom: 100%;
          }
        }
      }
    }
  }
}

.location-cta {
  color: #004E59;
  font-size: 1.4rem;
  background-color:  rgba(242,213,213,0.5);
  padding: 1rem 1.5rem;
  p {
    font-weight: 500;
    letter-spacing: -0.25px;
  }
  svg {
    fill: #D8197F;
    margin-right: 0.5rem;
  }
  a {
    display: inline-flex;
    align-items: center;
    &:hover svg {
      fill: white;
    }
    &:focus svg, &:active svg {
      fill: #8b114e;
    }
  }
}

@keyframes privateEventsFadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes privateEventsFadeOut {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

.weddings-private-events {
  .event-meta-wrapper {
    .btn-group {
      display: flex;
      justify-content: center;
      @media (min-width: 1024px) {
        justify-content: flex-start;
      }
      a {
        width: 30px;
        height: 30px;
        display: flex;
        justify-content: center;
        align-items: center;
        i {
          font-size: 0.8rem;
        }
      }
    }
  } 
  .related-events h5.title {
    font-family: 'Domaine';
    font-size: 1.6rem;
    line-height: 1.1;
    letter-spacing: -0.29px;
    color: #004E59;
  }
  .address {
    font-size: 1.2rem;
    line-height: 1.125;
    display: flex;
    justify-content: center;
    @media (min-width: 1024px) {
      display: block;
    }
  }
  .badge.placeholder {
    opacity: 0;
    @media (min-width: 1024px) {
      display: none;
    }
  }
  .container {
    @media (min-width: 1024px) {
      width: calc(100% - 24rem);
    }
  }
  #press-coverage {
    background-color: rgba(242,213,213,0.5);
    h2 {
      font-family: 'Domaine';
      margin-bottom: 1rem;
      color: #004E59;
      font-size: 1.6rem;
      line-height: 1.125;
    }
  }
  #header {
    height: 85vh;
    min-height: auto;
    max-height: none;
  }
  .header-slideshow .gallery-cell {
    height: 85vh;
    background-size: cover;
  }
  .page-wrapper {
    margin-top: -14rem;
  }
  .page-heading {
    h1 {
      font-family: 'Domaine';
      font-style: italic;
      font-size: 3.25rem;
      &.hero-bg {
        color: #004E59;
        &:before {
          background-color: #004E59;
        }
      }
    }
  }
  .breadcrumb-item a {
    color: #004E59;
  }
  .matrix-block-videoEmbed {
    p {
      height: 0;
      opacity: 0;
      margin: 0;
    }
    hr {
      margin: 3.5rem 0;
    }
  }

  .matrix-block-text {
    h3, h5 {
      color: #004E59;
      font-size: 1.6rem;
      font-family: 'Domaine';
      text-transform: none;
      line-height: 1.125;
    }
    .row > div > p:last-child {
      margin-bottom: 0;
    }
  }

  .gallery-cell {
    opacity: 0 !important;
    animation: privateEventsFadeOut ease 1s;
    &.is-selected {
      opacity: 1 !important;
      animation: privateEventsFadeIn ease 1s;
    } 
  }
}



.private-events-banner {
  display: flex;
  margin-bottom: 1rem;
  &.three-up {
    display: none;
    @media (min-width: 768px) {
      display: flex;
    }
    @media (min-width: 1024px) {
      display: none;
    }
    @media (min-width: 1260px) {
      display: flex;
    }
  }
  &.two-up {
    display: none;
    @media (min-width: 1024px) {
      display: flex;
    }
    @media (min-width: 1260px) {
      display: none;
    }
  }
  &.one-up {
    display: block;
    .private-events-banner-image {
      height: 200px;
    }
    .private-events-banner-text {
      height: auto;
    }
    > * {
      width: 100%;
    }
    @media (min-width: 768px) {
      display: none;
    }
  }
  > div {
    width: 50%;
  }
  &.bookstore-cafe {
    margin-top: 2rem;
    display: block;
    .private-events-banner-image {
      height: 200px;
    }
    .private-events-banner-text {
      height: auto;
    }
    > * {
      width: 100%;
    }
    @media (min-width: 768px) {
      display: flex;
      .private-events-banner-image {
        height: auto;
      }
    }
  }
  .private-events-banner-image {
    background-position: center center;
    background-size: cover;
  }
  .private-events-banner-text {
    padding: 1rem 2rem;
    background-color: #004E59;
    height: 300px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    h2 {
      font-family: 'Domaine';
      font-size: 3rem;
      color: white;
      line-height: 1;
    }  
    h3 {
      font-family: 'GTWalsheim';
      font-size: 1.4rem;
      line-height: 1.143;
      letter-spacing: -0.25px;
      color: rgba(255,255,255,0.75);
      font-weight: 500;
    }
  }
}

#location-calendar-app .private-events-banner-text h2 {
  margin-bottom: 0.5rem;
}