.badge{
  display: inline-flex;
  align-items: center;
  transition: all .3s ease;
  -webkit-font-smoothing: antialiased;
  .icon-wrapper{
    margin-right: 5px;
  }
  text-transform: uppercase;
  letter-spacing: 1px;

  svg, img, object{
    width: 20px;
    height: 20px;
    fill: $brand-secondary;
  }
}
