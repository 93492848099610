#form-donate-online {

  .input-group {
    margin-bottom: 1rem;
  }

  .form-control, .input-group-addon {
    background-color: $brand-secondary-lightest;
    color: $brand-primary;
    font-family: $font-family-brand;
    font-size: 1.6rem;
  }

  .form-control {
    border: 1px solid $brand-secondary-lightest;
    padding-top: 1rem;
    padding-bottom: 1rem;
    line-height: 1;
    padding-left: 0;
  }

  .input-group-addon {
    padding-right: 0.5rem;
  }

  label {
    margin-bottom: 0.25rem;
  }

  .btn {
    padding-left: 3rem;
    padding-right: 3rem;
  }
}

.membership-faqs {
  // background-color: #f2f2f2;
  // padding-right: 10px;
  // margin-right: 10px;
  display: flex;
  // flex-wrap: wrap;
  flex: initial;
  // justify-content: space-between;
  // align-content: space-between;

}

.faq-card {
    padding-right: 2rem;
  }
