.slideshow{
  background-color: $brand-secondary-dark;
  padding: 3rem 0 4rem;

	.container {
		transition: padding .5s;
		@include media-breakpoint-up(lg) {
			padding-right: 4.5rem;

			&.left-pad {
				padding-left: 4.5rem;
			}
		}
	}

  .slideshow-header{
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  h3{
    color: white;
    margin-bottom: 1.5rem;
  }
  h6{
    color: $brand-secondary-lightest;
  }
  .gallery{
    height: 285px;
  }

  .gallery-cell{
    img{
      height: 285px;
      display: block;
      margin-right: 1rem;
      opacity: .35;
      filter: grayscale(100%);
      transition: all .3s ease;
    }
    &.is-selected, &:hover{
      img{
        opacity: 1;
        filter: none;
      }
    }
  }
}

.matrix .slideshow{
  margin: 2rem 0;
}

/*! Flickity v2.0.2
http://flickity.metafizzy.co
---------------------------------------------- */



.flickity-enabled {
  position: relative;
}

.flickity-enabled:focus { outline: none; }

.flickity-viewport {
  overflow: hidden;
  position: relative;
  height: 100%;
}

.flickity-slider {
  position: absolute;
  width: 100%;
  height: 100%;
}

/* draggable */

.flickity-enabled.is-draggable {
  -webkit-tap-highlight-color: transparent;
          tap-highlight-color: transparent;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
}

.flickity-enabled.is-draggable .flickity-viewport {
  cursor: move;
  cursor: -webkit-grab;
  cursor: grab;
}

.flickity-enabled.is-draggable .flickity-viewport.is-pointer-down {
  cursor: -webkit-grabbing;
  cursor: grabbing;
}

/* ---- previous/next buttons ---- */

.flickity-prev-next-button {
  position: absolute;
  top: 50%;
  width: 44px;
  height: 44px;
  border: 1px solid $brand-secondary-lighter;
  border-radius: 0;
  background-color: transparent;
  cursor: pointer;
  transition: all .3s ease;
  /* vertically center */
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
}

.flickity-prev-next-button:hover {
  background: white;
  border-color:transparent;
  .arrow{
    fill: $brand-secondary;
  }
}

.flickity-prev-next-button:focus {
  outline: none;
  box-shadow: 0 0 0 5px #09F;
}

.flickity-prev-next-button:active {
  opacity: 1;
}

.flickity-prev-next-button.previous {
  left: -1.2rem;
  @include media-breakpoint-up(lg){
    left: -4rem;

  }
}
.flickity-prev-next-button.next {
   right: -1.2rem;
   @include media-breakpoint-up(lg){
     right: -4rem;
   }
 }
/* right to left */
.flickity-rtl .flickity-prev-next-button.previous {
  left: auto;
  right: 10px;
}
.flickity-rtl .flickity-prev-next-button.next {
  right: auto;
  left: 10px;
}

.flickity-prev-next-button:disabled {
  opacity: 0;
  cursor: auto;
}

.flickity-prev-next-button svg {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
}

.flickity-prev-next-button .arrow {
  fill: $brand-secondary-lighter;
}

/* ---- page dots ---- */

.flickity-page-dots {
  position: absolute;
  width: 100%;
  bottom: -25px;
  padding: 0;
  margin: 0;
  list-style: none;
  text-align: center;
  line-height: 1;
}

.flickity-rtl .flickity-page-dots { direction: rtl; }

.flickity-page-dots .dot {
  display: inline-block;
  width: 10px;
  height: 10px;
  margin: 0 8px;
  background: #333;
  border-radius: 50%;
  opacity: 0.25;
  cursor: pointer;
}

.flickity-page-dots .dot.is-selected {
  opacity: 1;
}

.header-slideshow {
  height: 100%;
  width: 100%;
  position: absolute;
  overflow:hidden;

  .gallery-cell {
    position: relative;
    min-height: 500px;
    height: 70vh;
    width: 100%;
    .radial-gradient {
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      background: radial-gradient(circle at center,rgba(0,0,0,0) 0, rgba(0,0,0,0.75) 100%)
    }
  }
}

.container.header-slider-control {
	cursor: pointer;
  display: flex;
  justify-content: flex-end;
  padding: 0 1rem;
  margin: 0 0 1rem;
  width: 100%;
  @media (min-width: 768px) {
    padding: 0 2.5rem;
  }
  @media (min-width: 1024px) {
    width: calc(100% - 12rem);
    padding: 0;
  }
  a {
    text-transform: uppercase;
    display: flex;
    position: relative;
    .pause, .play {
      display: inline-flex;
      height: 25px;
      align-items: center;
    }
    .play {
      position: absolute;
      width: 185px;
      padding-right: 1rem;
      display: flex;
      justify-content: space-between;
    }
    svg {
      stroke: #CF1974;
    }
    &:hover, &:focus {
      svg {
        stroke: #8b114e;
      }
    } 
    svg {
      margin-right: 0.5rem;
    }
  }
  &.paused {
    a .pause {
      opacity: 0;
    }
    a .play {
      opacity: 1;
    }
  }
  &.playing {
    a .pause {
      opacity: 1;
    }
    a .play {
      opacity: 0;
    }
  }
  
}