.card-location-link{
  display: block;

  @include media-breakpoint-up(md){
    height: 100%;
  }
}


.col-card-location {
  margin-bottom: 1rem;

  @media (min-width: 1024px) and (max-width: 1260px) {
    flex: 0 0 50%;
    max-width: 50%;
  }
}

.card-location {

  @include media-breakpoint-up(md){
    height: 100%;
  }

  p, .table {
		color: $brand-secondary-light;
	}

	address {
		margin-bottom: 10px;
	}

  .card-heading {
    height: 32px;
  }

	.table {
		width: 100%;
    margin-bottom: 0;
    td {
      border-top: 1px solid $gray-lighter;
      font-size: 0.75rem;
      font-weight: 500;
      padding: 0.2rem 0;
    }
	}

	.location-img {
    width: 100%;
		height: 200px;
    opacity: 1;
    object-fit: cover;
    transition: opacity .5s ease;
	}

  figure{
    position: relative;
    opacity: 1;
    transition: opacity .5s ease;
    margin-bottom: 0;
    .overlay{
      background-color: $tint-1;
      position: absolute;
      width: 100%; height: 100%;
      top: 0; left: 0;
      z-index: 1;
      opacity: .6;
    }
    .location-img{
      filter: grayscale(100%);
    }
    .icon-wrapper{
      position: absolute;
      z-index: 2;
      width: 100%;
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      svg{
        fill: white;
        width: 60px;
        height: 60px;
      }
    }
  }

  .location-map {
    position: absolute !important;
    top: 42px;
    left: 0;
    right: 0;
    opacity: 0;
    transition: opacity .5s ease;
  }

  .address {
    font-size: 0.85rem;
  }
}

@for $i from 1 through length($tints) {
  .col-card-location:nth-child(#{length($tints)}n+#{$i}) {
    &:hover {
      .card-location {
        background-color: nth($tints, $i);
      }
    }
    .card-location figure .overlay{
      background-color: nth($tints, $i);
    }
  }
}

.col-card-location:hover {
	.card-text, .card-title, p, table, .address, .badge {
		color: white;
	}
  svg {
    path {
      fill: white;
    }
  }

  .location-img, figure {
    opacity: 0;
  }

  .location-map {
    opacity: 1;

  }
}

.card-block {
	padding-top: 9px;
}

.card-title {
	color: $brand-secondary;
	margin-bottom: 5px;
}

.card-heading {
	display: flex;
	padding-top: 10px;
	padding-left: 15px;
	margin-bottom: 10px;
}
