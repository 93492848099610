.card-section{
  background-color: transparent;
  margin-top: 1.5rem;
  a{
    h3{
      transition: color .3s ease;
    }
    p{
      color: $body-color;
    }

    &:hover{
      img{
        filter: grayscale(100%);
      }
      .btn{
        opacity: 1;
      }
    }
  }

  .btn{
    left: 50%;
    top: 50%;
    transform: translateX(-50%) translateY(-50%);
  }

  figure{
    display: flex;
    flex-wrap: wrap;
    position: relative;
    align-items: center;
    justify-content: center;
    &::before{
      content:'';
      position: absolute;
      top: 0;
      left: 0;
      z-index: 1;
      width: 100%;
      height: 100%;
      background-color: transparent;
      transition: background .5s ease;
    }
  }
  .btn{
    position: absolute;
    opacity: 0;
    z-index: 2;
    transition: opacity .5s ease;
  }
  img{
    opacity: 1;
    transition: opacity .3s ease;
    width: 100%;
  }

  &.overlay-gray {
    opacity: 0.4;

    img {
      filter: grayscale(100%);
    }

    &:hover {
      opacity: 1;
    }
  }

  &.card-mediakit {
    img {
      max-height: 190px;
    }
  }
}

.card-section-advocacy{
  text-align: center;

  .icon-wrapper{
    height: 180px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 1rem;
  }
  svg{
    height: 180px;
    width: 180px;
  }
}

@for $i from 1 through length($tints) {
  .card-section-wrapper:nth-child(#{length($tints)}n+#{$i}) {
    .card-section-advocacy svg{
      fill: nth($tints, $i);
    }
    a:hover{
      figure::before{
        background-color: rgba(nth($tints, $i), .7);
      }
      h3, p{
        color: nth($tints, $i);
      }
    }
  }
}
