body.careers {

    .page-wrapper {
        margin-top: -10rem;
    }

    .container.fixed-cta {
        position: fixed;
        bottom: 0;
        right: 0;
        left: 0;
        display: flex;
        justify-content: flex-end;
        z-index: 10;
        padding: 0;
        background-color: transparent;
        pointer-events: none;
        aside {
            width: 100%;
            @media screen and (min-width: 768px) {
                width: 470px;
                margin-bottom: 1rem;
            }
            background-color: #F8EAEA;
            padding: 1rem;
            pointer-events: all;
        }
        a.btn {
            margin-top: 1rem;
            display: inline-flex;
            align-items: center;
            span {
                margin-left: 0.5rem;
            }
            &:hover svg g {
                stroke: white;
            }
                
        }
    }
    main {
        position: relative;
        #page-intro h2 {
            font-size: 1.8rem;
        }
        p {
            font-size: 1.4rem;
            line-height: 1.21428571429;
        }
        .matrix {
            h2 {
                font-size: 2.4rem;
            }
            .matrix-block-headline h3 {
                @media screen and (min-width: 768px) {
                    padding-left: 3rem;
                    width: 66%;
                }
                font-size: 1.6rem;
                margin-bottom: 1rem;
            }
            .matrix-block-text .page-text {
                margin-bottom: 1rem;
                @media screen and (min-width: 768px) {
                    padding-left: 3rem;
                }
                p {
                    font-size: 1rem;
                    line-height: 1.4;
                }
            }
            .matrix-block-inlineImage {
                figure {
                    @media screen and (min-width: 768px) {
                        padding-left: 3rem;
                        width: 66%;
                    }
                    .img-wrapper img {
                        padding: 0;
                    }
                    figcaption {
                        padding: 0;
                        max-width: none;
                        p {
                            padding-top: 1rem;
                            font-size: 0.75rem;
                            line-height: 1.2;
                        }
                        &:before {
                            display: none;
                        }    
                    } 
                    &:after {
                        content: "";
                        height: 1px;
                        width: 100%;
                        display: block;
                        background-image: linear-gradient(#3E4B51, #3E4B51);
                        background-position: top center;
                        background-size: 136px;
                        background-repeat: no-repeat;
                        margin-top: 3rem;
                    }
                }
            }
            .matrix-block-sectionTitle + .matrix-block-text {
                p {
                    font-size: 1.4rem;
                    line-height: 1.21428571429;
                }
                .page-text {
                    padding-left: 0;
                }
            }
            .matrix-block-quote {
                img {
                    border-radius: 50%;
                    width: 50%;
                    height: 50%;
                    margin-bottom: 1rem;
                    @media screen and (min-width: 768px) {
                        width: 17%;
                        height: 17%;
                        margin-bottom: 0rem;
                    }
                }
                blockquote {
                    background-color: transparent;
                    padding: 0;
                    @media screen and (min-width: 768px) {
                        width: 66%;
                    }
                    &:after, &:before {
                        display: none;
                    }
                    .quote, .quote p:last-of-type {
                        margin-bottom: 0;
                    }
                    .label p:before {
                        content: "—";
                    }
                }
            }
            .matrix-block-videoEmbed {
                figure {
                    @media screen and (min-width: 768px) {
                        padding-left: 3rem;
                        width: 66%;
                    }
                    padding-bottom: 1rem;
                    margin-bottom: 1rem;
                    figcaption p {
                        padding-top: 1rem;
                        color: #778185;
                        text-align: center;
                        font-size: 0.75rem;
                        line-height: 1.2;
                    }
                    &:after {
                        content: "";
                        height: 1px;
                        width: 100%;
                        display: block;
                        background-image: linear-gradient(#3E4B51, #3E4B51);
                        background-position: top center;
                        background-size: 136px;
                        background-repeat: no-repeat;
                        margin-top: 3rem;
                    }
                }
            }
            .matrix-block-slideshow .slideshow {
                margin: 0;
                padding: 1rem 0;
                background-color: transparent;
                .container:after {
                    content: "";
                    height: 1px;
                    @media screen and (min-width: 768px) {
                        width: 66%;
                    }
                    display: block;
                    background-image: linear-gradient(#3E4B51, #3E4B51);
                    background-position: top center;
                    background-size: 136px;
                    background-repeat: no-repeat;
                }
                .slideshow-header {
                    display: none;
                }
                .gallery {
                    @media screen and (min-width: 768px) {
                        padding-left: 3rem;
                    }
                    height: auto;
                    .figure a {
                        display: flex;
                        flex-direction: column;
                        align-items: center;
                    }
                    .flickity-viewport {
                        margin-bottom: 2rem;
                    }
                    figcaption {
                        max-width: fit-content;
                        margin: 0 auto;
                        p {
                            padding-top: 1rem;
                            color: #778185;
                            text-align: center;
                            font-size: 0.75rem;
                            line-height: 1.2;
                        }
                    }
                }
            }
        }
    }
    .matrix-block-text a {
        color: #CF1974;
        background-image: linear-gradient(#CF1974 0%, #CF1974 100%);
        &:hover {
            background-image: linear-gradient(#606A6E 0%, #606A6E 100%);
        }
    }
    .matrix-block-quote .container {
        justify-content: flex-start;
    }
}

#sticker-wrapper:not(.closed) ~ .page-wrapper {
    .fixed-cta {
        @media screen and (max-width: 768px) {
            display: none;
        }
    }
}

body.careers .page-wrapper {
    @media screen and (max-width: 768px) {
        margin-top: 0;
        transform: translateY(-134px);
    }
}

#sock {
    .container {
        height: auto;
        @media screen and (min-width: 768px) {
            a {
                color: #CF1974;
                background-image: linear-gradient(#CF1974 0%, #CF1974 100%);
                &:hover {
                    background-image: linear-gradient(#606A6E 0%, #606A6E 100%);
                }
            }
        }
    }
}
