article {
  ul{
    padding: 0;
    li{
    display: inline;
    }
  }
}

.event-page-intro {
  padding-bottom: 0.5rem;
}

.events-alert {
  position: relative;
  background-color: white;
  align-items: center;
  padding: 1rem .25rem;
  padding-right: 4rem; //room for the exclamation icon
  border-color: transparent !important;

  @include media-breakpoint-up(sm){
    display: flex;

  }

  .exclamation-icon {
    position: absolute;
    right: 1rem;
    top: 50%;
    transform: translateY(-50%);
    font-size: 3rem;
    font-weight: 600;
    color: $brand-primary;
    justify-content: center;
    align-items: center;
  }

  .card-event-date{
    padding: 0;
    align-self:center;
  }
  p {
    margin-bottom: 0;
    color: $brand-secondary-light;
  }

  .alert-inner {
    display: flex;
    align-items: center;
  }
}

#todays-events .events-alert{
  padding-left: 1rem;
}


.event-column {
  display: flex;
  flex-wrap: wrap;
}

.event-cat {
  padding-left: 5px;
  padding-right: inherit;
}

.event-location, .card-text {
  padding-right: 1rem;
}

.card-title {
  margin-right: 1rem;
}

.sticky {
  position: sticky;
  top: 0px;
  background-color: $brand-secondary-lightest;
  z-index: 1;
}

.card-featured-event {
  min-height: 300px;
  position: relative;
  margin-bottom: 2rem;
  overflow: hidden;

  .bg-img{
    position: absolute;
    width: 100%;
    height: 100%;
    background-size: cover;
    background-position: center center;
    background-repeat: no-repeat;
    transition: transform .8s ease;
  }

  .card-block {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    background-color: rgba($brand-secondary-dark,0.95);
    flex-grow: 0;
    width: 100%;
    min-height: 3.75rem;
    position: absolute;
    bottom: 0;
    padding-bottom: 0.3rem;
    transition: background .3s ease;

    @include media-breakpoint-down(sm){
      flex-direction: column;
      align-items: flex-start;
    }

    .badge, .card-event-date, .card-event-date .month, h4, h5  {
      color: white;
    }

    svg path {
      fill: white;
    }

    h4 {
      margin-bottom: 0.1rem;
    }

    .card-event-date {
      padding: 0;
      align-self: auto;
    }
  }
  &:hover{
    .bg-img{
      transform: scale(1.03);
    }
    .card-block{
      background-color: $brand-secondary-dark;
    }
  }

}


.upcomingEvent-block {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
}

.annual-event {
  border-spacing: 2px;
}

@for $i from 1 through length($tints) {
  .annual-event-card:nth-of-type(#{length($tints)}n+#{$i}) {
    background-color: nth($tints, $i);
  }
}

.annual-event-card {
  border-radius: 0;
  min-height: 240px;
  border-right: 1px solid rgba($brand-secondary-lightest, 0.6);

  &:nth-of-type(4n) {
    border-right-color: transparent;
  }

  a {
    height: 100%;
  }

  .card-block {
    padding: 1rem 1.25rem;
  }

  .card-title {
    font-size: 31px;
    font-weight: 500;
  }

  .badge, .card-title {
    color: white;
  }

  .badge {
    opacity: 0.6;

    svg {
      margin-right: 0.25rem;
    }
    path {
      fill: white;
    }
  }
}

.row-event-img {
  padding-left: .75rem;
  width: 43rem;
  // This cant be right
}
.event-img-date {
  background-color: black;
  color: white;
  margin-top: 270px;
  height: 70px;
  // width: 70px;
}

#event-img {
  background: no-repeat;

}

#calendar-app {
  padding-top: 0.3rem;

  .category-nav {
    margin-bottom: 2rem;
  }
}

#calendar-wrapper {
  background-color: transparent;
  padding-top: 0.25rem;

  .month-listing {
    border-bottom: 4px solid $brand-secondary;
    padding: 0.5rem 0;

    margin-top: 2.5rem;
  }

  #btn-datepicker {
    z-index: 2;
    position: absolute;
    right: 0;
    margin-top: 2.5rem;
    background-color: transparent;
    border: 1px solid $brand-secondary-lighter;
    padding-left: 0.3rem;
    padding-right: 0.3rem;
    padding-top: 0.2rem;
    padding-bottom: 0;
    cursor: pointer;

    svg path{
      fill: $brand-secondary-light;
    }
    &.active, &:hover{
      background-color: $brand-secondary;
      border-color: $brand-secondary;
      svg path{
        fill: white;
      }
    }
  }
}

.event-listing {
  display: block;
  border-bottom: 1px solid;
  align-items: center;
  padding: 1.25rem .25rem .75rem;
  @include media-breakpoint-up(sm){
    display: flex;
  }

  &:hover {
    background-color: white;
  }

  & > * {
    // added so only the .event-listing link is the target element on click,
    // which is used to set the background color of the corresponding detail drawer in Vue
    pointer-events: none;
  }
  h3{
    margin-right: 1rem;
  }
  h5{
    margin-bottom: 0;
  }
  h4 {
    font-family: $font-family-brand;
    margin-bottom: 0.2rem;
  }

  .card-event-date {
    align-self: auto;
		min-width: 50px;
    padding: 0;
  }

  .badge-wrapper {
    display: flex;
    flex-wrap: wrap;
		max-width: 100%;

    .badge {
      margin-bottom: 0;
      margin-right: 1rem;

      &.badge-time {
        margin-right: 2rem;
      }
    }
  }

  .event-listing-location {
    margin-left: 1rem;
  }
}

@for $i from 1 through length($tints) {
  .event-listing:nth-of-type(#{length($tints)}n+#{$i}) {
    border-color: nth($tints, $i);
  }
}

#datepicker {
  z-index: 3;
  background-color: white;
  max-height: 0;
  overflow: hidden;
  position: absolute;
  top: 5.5rem;
  left: 0;
  right: 0;
  transition: all .5s ease;
  @include media-breakpoint-down(xs){
    margin-left: -1.5rem;
    margin-right: -1.5rem;
  }

  .datepicker-inline {
    width: 100%;
  }

  .table-condensed {
    width: 100%;
  }

  &.visible {
    max-height: 1000px;
  }

  .datepicker{
    border-top: 4px solid $brand-secondary;
    border-bottom: 1px solid $brand-secondary;
    border-radius: 0;

    *{
      border-radius: 0;
    }
    thead{
      border-bottom: 1px solid $brand-secondary;
    }

    td, th {
      color: $brand-secondary;
      font-weight: 500;
      font-size: 1.2rem;
    }

    .datepicker-days td, th{
      padding: .7rem;
      @include media-breakpoint-down(xs){
        padding: .5rem .6rem;
      }
    }

    .prev, .next, .datepicker-switch{
      &:hover{
        color: $brand-primary;
        background-color: white;
      }
    }

    .datepicker-switch{
      color: $brand-secondary-light;
    }
    .dow{
      font-weight: 600;
      text-transform: uppercase;
      font-size: .8rem;
      letter-spacing: 1.6px;
      color: $brand-secondary-light;
      padding: 0 .7rem .4rem;
    }

    .past-date {
      background-color: $brand-secondary-lightest;
      color: rgba($brand-secondary-light, .3);
    }

    .active{
      color: $brand-secondary;
      background-color: inherit;
      text-shadow: none;
    }

    .has-events {
      position: relative;
      &:hover{
        color: $brand-primary;
        background-color: white;
      }
      &:after{
        position: absolute;
        content:'';
        width: 1.5rem;
        height: 3px;
        background-color: $brand-primary;
        margin-left: -.75rem;
        bottom: .7rem;
        left: 50%;
        @include media-breakpoint-down(xs){
          height: 2px;
          bottom: .6rem;


        }
      }
      &.today:after{
        background-color: white;
      }
    }
    .today{
      background-color: $brand-primary !important;
      color: white !important;
    }
    .datepicker-months td, .datepicker-years td{
      padding: 0;
    }
    .month, .year{
      margin: 0;
      width: 25%;
      &.disabled{
        background-color: $brand-secondary-lightest;
      }
      &:not(.disabled):hover{
        color: $brand-primary;
        background-color: white;
      }
      &.focused{
        background-color: $brand-primary;
        color: white;
      }
    }
  }
}
