.draggable {
  position: absolute;
  z-index: 12;

  &.draggable-lg {
    left: 15px;
    top: 65px;
    @include media-breakpoint-up(md){
      right: 3rem;
      top: calc(70vh - 18rem);
      left: inherit;
    }
    @media (min-height: 1000px){
      top: calc(700px - 18rem);
    }
    @media (min-width: 1600px){
      top: 8rem;
    }
  }

  &.draggable-sm {
    right: 15px;
    top: 315px;
    @include media-breakpoint-up(md){
      right: 13rem;
      top: calc(70vh - 14rem);
    }
    @media (min-height: 1000px){
      top: calc(700px - 14rem);
    }
    @media (min-width: 1600px){
      top: 13rem;
    }
  }
  &:hover, &:focus, &.is-pointer-down {
    z-index: 13;
  }

}
body.homepage .draggable{
  position: fixed;
}

.sticker{
  background-color: $brand-primary;
  border-radius: 50%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  background-size: cover;
  box-shadow: 3px 3px 6px 1px rgba(black, 0.2), inset 3px 3px 6px 0 rgba(white, .4);
  transition: transform .2s;

  @include media-breakpoint-down(sm) {
    flex-direction: column;
    padding: 20px;
  }

  &:hover, &:focus, &.is-pointer-down {
    @include media-breakpoint-up(md){
      transform: scale(1.4);

      .expanded-content{
        opacity: 1;
      }
      .sticker-text{
        opacity: 0;
      }
    }
  }
  h3{
    color: white;
    text-transform: uppercase;
    font-size: 20px;
    line-height: 1.3;
    text-align: center;
    margin-bottom: 0;
  }

  .expanded-content{
    @include media-breakpoint-up(md) {
      padding: 15px;
      opacity: 0;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translateX(-50%) translateY(-50%);
      width: calc(100% - 30px);
    }
  }

}


.draggable-lg .sticker {
  width: 180px;
  height: 180px;
  padding: 15px;
  @include media-breakpoint-down(sm){
    width: 280px;
    height: 280px;
  }
  .expanded-content{
    @include media-breakpoint-down(sm) {
      text-transform: none;
      font-family: $font-family-sans-serif;
      font-weight: 500;
      margin-top: 5px;
    }
  }
}

.draggable-sm .sticker {
  width: 120px;
  height: 120px;
  @include media-breakpoint-down(sm){
    width: 200px;
    height: 200px;
  }

  h3{
    @include media-breakpoint-up(md) {
      font-size: 11px;
    }
  }
}

#sticker-wrapper{
  z-index: 400;
  @include media-breakpoint-down(sm){
    position: fixed;
    width: 100vw;
    height: 100vh;
    background-color: rgba($gray-lighter, .9);
    display: flex;
    justify-content: center;
  }
}

#sticker-wrapper.closed{
  background-color: transparent;
  height: 0;
  overflow: hidden;
  display: none;
  .sticker{
    transform: scale(0);
  }
  .sticker-dismiss{
    display: none;
  }
  #sticker-toggle{
    background-color: $brand-primary;
  }
}

.sticker-dismiss{
  position: absolute;
  left: 0;
  bottom: 70px;
  width: 100%;
}

#sticker-toggle{
  border-radius: 50%;
  padding: .7rem;
  position: absolute;
  right: 50px;
  top: 20px;
  z-index: 501;
  background-color: transparent;
  &:hover{
    background-color: transparent;
  }
  &.active{
    background-color: $brand-primary;

  }
}
