#subnav {
  position: sticky;
  top: 0;
  z-index: 3;
  background-color: white;
  -webkit-font-smoothing: antialiased;
  margin-top: -2rem;
  margin-bottom: 1rem;
	max-width: 100%;
	overflow-x: auto;

  @include media-breakpoint-up(md) {
    padding: 1rem 0;
  }

  .container {
    @include media-breakpoint-down(sm) {
      padding: 0;
    }
  }
  ul {
    -webkit-overflow-scrolling: touch;

    @include media-breakpoint-up(md) {
      margin-left: -0.75rem;
    }
  }
  .nav-link {
    color: $brand-secondary-lighter;
    font-size: 1.2rem;
    font-weight: 500;
    padding: 0.5rem 0.75rem;
    white-space: nowrap;
    transition: all 0.5s ease;
    &:hover {
      color: $brand-primary;
    }
    &.active {
      background-color: $tint-1;
      color: white;
    }

  }
  .nav-item:first-child {
    padding-left: 0;
  }
}

.donate {
  #subnav {
    margin-top: -0.5rem;
    margin-bottom: 4rem;
  }
}
