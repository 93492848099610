#press-release{
  padding-top: 0;
  .press-contacts{
    position: sticky;
    top: .5rem;
    .label{
      @include media-breakpoint-up(md) {
        display: flex;
        flex-wrap: wrap;
      }
      p{
        @include media-breakpoint-up(md) {
          padding-right: 2rem;
        }
        @include media-breakpoint-up(lg) {
          padding-right: 0;
        }
      }
    }
  }
}

.report-block{
  max-width: 50%;
  margin-top: 1rem;
  padding-right: 1rem;
  figure{
    max-width: 160px;
    margin-bottom: 0;
    .overlay{
      position: absolute;
      width: 100%;
    }
  }
  img{
    margin-bottom: .5rem;
  }
  &:hover{
    img{
      transition: all .2s;
      transform: translate(.2rem, -.2rem);
    }
  }
}

@for $i from 1 through length($tints) {
  .report-block:nth-child(#{length($tints)}n+#{$i}) {
    &:hover{
      .figure-caption{
        color: nth($tints, $i);
      }
      img{
        box-shadow: -.2rem .2rem 0px 0px nth($tints, $i);
      }
    }
  }
}

.card-mediakit{
  margin-top: .5rem !important;

  figure{
    border: 1px solid $gray-lighter;
    margin-bottom: .5rem;
  }

  .label{
    color: $body-color;
  }

  &:hover{
    img{
      filter: grayscale(100%);
    }
    .btn{
      opacity: 1;
    }
  }

}

@for $i from 1 through length($tints) {
  .mediakit-wrapper:nth-child(#{length($tints)}n+#{$i}) {
    .card-mediakit:hover{
      .label{
        color: nth($tints, $i);
      }
    }
  }
}
