@import 'variables';
@import '../node_modules/bootstrap/scss/bootstrap.scss';

@import 'fonts';
@import 'layout';
@import 'events.scss';
@import 'press';
@import 'donations';
@import 'location-detail';
@import 'event-detail';
@import 'homepage';
@import 'about';
@import 'advocacy-update';
@import 'photoswipe';
@import 'default-skin';
@import 'bootstrap-datepicker';
@import 'locations';
@import '_search';
@import 'livestream';
@import 'careers';

@import '_components/button';
@import '_components/header';
@import '_components/hero-img';
@import '_components/page-eyebrow';
@import '_components/subnav';
@import '_components/callout-footer';
@import '_components/newsletter';
@import '_components/footer';
@import '_components/forms';
@import '_components/filters';
@import '_components/tags';
@import '_components/matrix';
@import '_components/slideshow';
@import '_components/card-location';
@import '_components/card-section';
@import '_components/card-gray-shadow';
@import '_components/card-press';
@import '_components/card-shop';
@import '_components/card-update';
@import '_components/share-block';
@import '_components/details-event';
@import '_components/event-detail-drawer';
@import '_components/card-event';
@import '_components/location-info-window';
@import '_components/services';
@import '_components/stickers';
@import '_components/store-hour-changes';

html {
  font-size: 16px;
  @include media-breakpoint-up(md) {
    font-size: 17px;
  }
  @media (min-width: 1180px) {
    font-size: 18px;
  }

  @include media-breakpoint-up(xl) {
    font-size: $font-size-root; // 20px
  }
}

body {
  -webkit-font-smoothing: antialiased;
	overflow-x: hidden;
}

//BASIC TYPOGRPAHY
h1 {
  font-weight: 800;
  @include media-breakpoint-up(sm) {
    font-size: 2.8rem;
  }
}

h4,
h5 {
  font-family: $font-family-sans-serif;
  font-weight: 500;
}

h5 {
  line-height: 1.2;
}

h6 {
  font-family: $font-family-sans-serif;
  text-transform: uppercase;
  font-size: .8rem;
  letter-spacing: 1.6px;
}

p a,
.matrix-block-text a,
#financials a {
  background-image: linear-gradient(
    $brand-secondary-light 0%,
    $brand-secondary-light 100%
  );
  background-repeat: repeat-x;
  background-size: 1px 1px;
  background-position: 0 90%;
  color: $gray-darker;
  transition: all .3s ease;
  &:hover {
    color: $brand-secondary;
    background-image: linear-gradient($brand-primary 0%, $brand-primary 100%);
  }
}

.lead,
.lead p {
  font-size: 1.4rem;
  color: $brand-secondary-light;
  line-height: 1.25;
  // max-width: 940px;
}

.advocacy .lead {
  margin-bottom: 1rem;
  padding-bottom: 1rem;
}

.label {
  font-size: 15px;
  font-weight: 500;
}

.figure-caption {
  text-align: center;
  max-width: 600px;
  margin: auto;
}

//SHARED STYLES
section {
  padding: 2.5rem 0;
}

.section-gray {
  background-color: $brand-secondary-lightest;
}

//BUTTONS
.btn {
  font-family: $font-family-sans-serif;
  font-size: .8rem;
  font-weight: 600;
  text-transform: uppercase;
  font-size: .8rem;
  letter-spacing: 1.6px;
  -webkit-font-smoothing: antialiased;
  &:focus {
    outline: none;
  }
}

.btn-sm {
  font-size: 11px;
}

.btn-lg {
  font-size: 1.2rem;
}

.btn-primary {
  &:hover,
  &:focus {
    background-color: $tint-2;
    color: white;
    box-shadow: none;
    border-color: $tint-2;
  }
}
.btn-secondary {
  border: 1px solid $brand-primary;
}

.btn-external {
  position: relative;
  padding-right: 2.3rem;
  &::after {
    content: url('/images/ext-link-icon.svg');
    display: inline-block;
    margin-left: 8px;
    top: 50%;
    margin-top: -13px;
    position: absolute;
  }
}

.btn-white {
  background-color: transparent;
  color: white;
  border-color: white;
  &:hover {
    background-color: $brand-primary;
    border-color: $brand-primary;
    color: white;
  }
}

.btn-dismiss {
  padding: .5rem;
  padding-bottom: .3rem;
  &::before {
    content: url('/images/close-icon.svg');
  }
}

.btn-secondary {
  &:hover {
    background-color: $brand-primary;
    color: white;
  }
}

.btn-info {
  &:hover {
    background-color: $brand-secondary;
    color: white;
    border-color: $brand-secondary;
  }
}

.btn-all-events {
  margin: .5rem 0 1.25rem 0;
  padding: 0.5rem;
  line-height: 1;
  display: inline-flex;
  align-items: center;

  svg {
    width: 20px;
    margin-right: 0.75rem;

    path {
      fill: $brand-primary;
      transition: all 0.3s ease-in-out;
    }
  }

  &:hover {
    svg {
      path {
        fill: white;
      }
    }
  }
}

#loading {
  height: 100vh;
  width: 100%;
  position: absolute;
  z-index: 9;
  transition: all .8s ease;
  background-color: $brand-secondary-lightest;
  display: flex;
  justify-content: center;
  padding-top: 200px;
  h5 {
    margin-top: 1rem;
    color: $brand-primary;
  }
  .loader-wheel {
    font-size: 64px; /* change size here */
    position: relative;
    height: 1em;
    width: 1em;
    padding-left: 0.45em;
    overflow: hidden;
    margin: 0 auto;
    animation: loader-wheel-rotate 1s steps(12) infinite;
  }

  .loader-wheel i {
    display: block;
    position: absolute;
    height: 0.3em;
    width: 0.1em;
    border-radius: 0.05em;
    background: $brand-primary;
    opacity: 0.8;
    transform: rotate(-30deg);
    transform-origin: center 0.5em;
  }

  @keyframes loader-wheel-rotate {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(360deg);
    }
  }
}

.loadingFade-enter,
.loadingFade-leave-to,
.loadingFade-leave-active {
  opacity: 0;
  // height: 0;
}

.loadingFade-enter-to,
.loadingFade-leave {
  opacity: 1;
  // height: 400px;
}
