.contact-info {
	button {
		margin-bottom: 1.5rem;
	}
}

.location-items {

	margin-right: 1.5rem;


}

.badge.subcategory {
	margin-right: 1.3rem;
	margin-top: .5rem;
	margin-bottom: .5rem;
	svg {
		width: 22px;
		height: 22px;
		path {
			fill: $tint-2;
		}
	}
	.icon-wrapper{
		margin-right: .5rem;
	}
}
.location-main-col{
	margin-bottom: 2rem;
	@include media-breakpoint-up(md){
		margin-bottom: 0;
		padding-right: 1rem;
	}
}


.btn-group{
	.fa{
		font-size: 1rem;
	}
}

.location-upcoming {
	margin-top: 3rem;
	margin-bottom: 1rem;
}

#location-calendar-app {

	h2 {
		margin-bottom: 1.5rem;
	}

	#calendar-wrapper {
		padding-top: 0;

		.month-listing {
			margin-top: 0;
			border-bottom: none;
		}
	}
}

.collapse-wrapper {
	background-color: white;
	padding: 1.25rem;
	margin-top: 2.5rem;

	h3 {
		margin-bottom: 0;
	}

	.link-collapse {
		display: flex;
    justify-content: space-between;

		.icon-open {
			display: none;
		}
		.icon-close {
			display: block;
		}

		&.collapsed {
			.icon-open {
				display: block;
			}
			.icon-close {
				display: none;
			}
		}
	}

	#inquiryCollapse {
		margin-top: 2.5rem;
	}
}

.logos-wrapper {
	display: flex;
	justify-content: flex-start;
	flex-wrap: wrap;

	.logo-link {
		flex-basis: 50%;
		padding-right: 3.75rem;
		margin-bottom: 3.25rem;

		@include media-breakpoint-up(md) {
			flex-basis: 25%;
		}

		@include media-breakpoint-up(lg) {
			flex-basis: 20%;
		}
	}
}

#additional-locations {
	.slideshow {
		margin-top: 0;
		margin-bottom: 2.5rem;
	}

	h3 {
		margin-bottom: 1.5rem;
	}


	.col-card-additional-location:hover {

	  .location-img, figure {
	    opacity: 0;
	  }

	  .location-map {
	    opacity: 1;
	  }
	}
}

.btn-all-locations {
	margin-top: 1.5rem;
}
