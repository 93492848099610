#footer{
  background-color: $gray-darker;
  color: white;
  -webkit-font-smoothing: antialiased;
  padding: 1.5rem 0 .75rem;
   position: relative;
  z-index: 11;
  svg{
    width: 230px;
    height: 30px;
    margin-bottom: 1.5rem;
  }
  a{
    color: white;
    transition: color .3s ease;
    &:hover{
      color: $brand-primary;
    }
  }

  .list-inline-item{
    margin-right: 1rem;
  }
  .donate{
    color: $brand-primary;
    &:hover{
      color: $tint-1;
    }
  }

  .social-container {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
  }

  dl.list-social-media{
    display: flex;
    flex-wrap: wrap;
    max-width: 220px;
    align-items: center;
    @include media-breakpoint-up(md) {
      margin-right: 0;
      margin-left: auto;
    }
    dd, dt{
      margin-bottom: .5rem;
    }
    dt{
      opacity: .4;
    }
  }

  .list-icons{
    display: flex;
    margin-bottom: 0;
    a{
      padding-left: 1rem;
      opacity: .4;
      transition: opacity .3s ease;
      &:hover{
        opacity: 1;
      }
    }
    li:first-of-type a {
      padding-left: 0rem;
    }
  }

}

.sub-footer {
  border-top: 1px solid $gray-darkest;
  padding-top: .75rem;
  .container{
    @include media-breakpoint-up(md) {
      display: flex;
      justify-content: space-between;
    }
  }
  ul{
    margin-bottom: .5rem;
    @include media-breakpoint-up(md) {
      margin-bottom: 0;
    }
  }

}
