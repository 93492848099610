.card-event {
  flex-direction: column;
  margin-bottom: 1.2rem;
  border-bottom: 1px solid;
  transition: background .3s ease;
  @include media-breakpoint-up(sm){
    flex-direction: row;
    align-items: center;

  }

  .card-event-img {
    max-width: 100%;
    @include media-breakpoint-up(md){
      max-width: 400px;
      margin-right: 1.5rem;
    }
  }

  .badge{
    margin-bottom: .5rem;
  }
  p{
    color: $body-color;
  }
  &:hover{
    .badge, p, h3, h5{
      color: white;
    }
    hr{
      border-color: white;
    }
    svg path{
      fill: white;
    }
  }
}

@for $i from 1 through length($tints) {
  .card-event:nth-child(#{length($tints)}n+#{$i}) {
    border-color: nth($tints, $i);
    &:hover{
      background-color: nth($tints, $i);
    }
  }
}

.card-event-date {
  padding-top: 0.8rem;
  padding-left: 0.675rem;
	padding: 0.8rem 0 0.675rem 0.8rem;
  align-self: flex-start;
  color: $brand-secondary;
  margin-right: 1.625rem;

  @include media-breakpoint-down(sm){
    padding-bottom: .8rem;
    display: flex;
    align-items: center;
  }
  @include media-breakpoint-down(xs){
    margin-right: 0;
  }


  .month {
    font-size: 16px;
    font-weight: 500;
    text-align: center;
    letter-spacing: 2px;
    margin-bottom: 0.2rem;
    color: $brand-secondary;

    @include media-breakpoint-down(sm){
      margin-right: 5px;
      margin-bottom: 0;
      text-align: left;
    }
  }

  h5 {
    text-align: center;
    margin-bottom: 0;
    @include media-breakpoint-down(sm){
      font-size: 16px;
      font-weight: 500;
      line-height: $line-height-base;
      color: $brand-secondary;
    }
  }

  .time {
    font-size: 16px;
    font-weight: 500;
    color: $brand-secondary;
    text-align: center;
    margin-bottom: 0;
  }

  hr {
    border-top-color: $brand-secondary-lighter;
    width: 55px;
    margin: 0.4rem 0;
    @include media-breakpoint-down(sm){
      width: 15px;
      margin: 0 5px;
    }
  }
}
.card-event-text{
  @include media-breakpoint-down(sm){
    padding: .8rem;
  }
}
