.page-wrapper{
  transition: transform .50s ease-in-out;
  margin-top: -10rem;
  position: relative;

  &.scroll-down{
    @include media-breakpoint-up(lg) {
      transform:translate(0, 10rem);
      .page-heading .hero-bg{
        color: rgba(black, .6);
        &:before{
          background-color: rgba(black, .6);
        }
      }
      .subtitle{
        color: $brand-secondary;
      }
    }
  }
}

body.homepage .page-wrapper {
  margin-top: 0rem;
  &.scroll-down{
    transform: translate(0,0);
  }


}

.container {
  width: 100%;
  padding: 0 1rem;
  @include media-breakpoint-up(md) {
    padding: 0 2.5rem;
    width: 100%;
  }
  @include media-breakpoint-up(lg) {
    margin-right: 0;
    margin-left: 12rem;
    width: calc(100% - 12rem);
  }

  @include media-breakpoint-up(xl) {
    margin-right: auto;
    width: 1180px;

  }
  @media (min-width: 1750px){
    margin-left: auto;
  }
}
