.card-gray-shadow{
  background-color: $brand-secondary-lightest;
  padding: .75rem;
  margin-bottom: 1.5rem;
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  .bottom-content{
    align-self: flex-end;
  }
  .contact-info{
    a{
      color: $brand-secondary-light;
      &:hover{
        color: $brand-primary;
      }
    }
  }
  .contact-info{
    margin-bottom: .2rem;
  }
  .label{
    color: $brand-secondary-light;
  }
}
a.card-gray-shadow{
  transform: translateY(0) translateX(0);
  transition: all .3s ease;
  &:hover, &:focus{
    // transform: translateY(.3rem) translateX(.3rem);
    .label, h5, .badge{
      color: white;
      svg{
        fill: white;
      }
    }
  }
}
.card-gray-wrapper{
  display: flex;
  flex-wrap: wrap;
}

@for $i from 1 through length($tints) {
  .card-gray-wrapper:nth-child(#{length($tints)}n+#{$i}) {
    .card-gray-shadow{
      box-shadow: .3rem .3rem 0px 0px nth($tints, $i);
    }
    a.card-gray-shadow:hover{
      background-color: nth($tints, $i);
      box-shadow: none;
    }
  }
}
