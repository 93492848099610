.service-block{
  display: flex;
  margin: 1rem 0;
  .icon-wrapper{
    width: 3.5rem;
    flex-shrink: 0;
    svg{
      overflow: visible;
      width: 2.5rem;
      height: 2.5rem;
      @include media-breakpoint-up(md){
        width: 3rem;
        height: 3rem;
      }
    }
  }
  h5{
    margin-bottom: 0;
    align-self: center;
  }
}

@for $i from 1 through length($tints) {
  .service-block-wrapper:nth-child(#{length($tints)}n+#{$i}) {
    .service-block:hover{
      h5{
        color: nth($tints, $i);
      }
      svg path{
        fill: nth($tints, $i);
      }
    }
  }
}
