#history-drawer {

  h3 {
    color: white;
  }
}

#history {
  .event-listing{
    align-items: flex-start;
    @include media-breakpoint-up(md){
      align-items: center;
    }
  }
  @for $i from 1 through length($tints) {
    .event-listing:nth-of-type(#{length($tints)}n+#{$i}) {

      .milestone-year {
        color: nth($tints, $i);
        margin-bottom: 0;
      }
    }
  }
}




//LEADERSHIP
.person-link-wrapper{
  @include media-breakpoint-up(xl){
    flex: 0 0 20%;
    max-width: 20%;
  }
}

.person-bg-img {
  padding-bottom: 100%;
  background-size: cover;
  background-repeat: no-repeat;
}

.event-drawer {
  .person-bg-img {
    padding-bottom: 56.67%;
  }
}

.person-link{
  .card {
    background-color: transparent;
    margin: .75rem 0;
  }
  color: $body-color;
  figure{
    margin-bottom: .5rem;
    @include media-breakpoint-down(xs){
      display: none;
    }
  }
  h5{
    margin-bottom: 0;
  }
  img, .person-bg-img{
    filter: grayscale(100%);
  }
  &:hover{
    img, .person-bg-img{
      filter: grayscale(0);
    }
  }
  // .no-image{
  //   @include media-breakpoint-down(xs){
  //     display: none;
  //   }
  // }

  &.no-hover:hover {
    cursor: default;
    img{
      filter: grayscale(100%);
    }
    h5, small {
      color: $body-color !important;
    }
  }
}

@for $i from 1 through length($tints) {
  .person-link-wrapper:nth-of-type(#{length($tints)}n+#{$i}) {
    .person-link{
      border-color: nth($tints, $i);
      &:hover{
        h5, small{
          color: nth($tints, $i);
        }
      }
    }
  }
}

//FUTURE

.card-goal{
  border-top: 1px solid;
  background-color: transparent;
  padding-top: .5rem;
  margin: .75rem 0;

}

@for $i from 1 through length($tints) {
  .card-goal-wrapper:nth-of-type(#{length($tints)}n+#{$i}) {

    .card-goal {
      border-top-color: nth($tints, $i);
    }
    h5 {
      color: nth($tints, $i);
    }
  }
}
