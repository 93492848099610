#nav-main {
  position: relative;
  z-index: 20;
  padding: 0;
  flex-direction: row;
  @include media-breakpoint-up(lg) {
    position: fixed;
    flex-wrap: wrap;
    padding: 1.5rem;
    padding-left: 0;
    height: 70vh;
    min-height: 500px;
    max-height: 700px;
    max-width: 12rem;
  }

  #logo-icon{
    width: calc(100% - 90px);
    padding-left: 10px;
    padding-top: 10px;
    display: block;
    position: relative;
    @media (max-width: 360px){
      padding-left: 10px;
      padding-top: 13px;
    }
    @include media-breakpoint-up(lg) {
      width: 100%;
      padding-left: 2rem;
      padding-top: 0;
    }
    svg{
      width: 260px;
      height: 34px;
      @media (max-width: 360px){
        width: 220px;
        height: 29px;
      }
      @include media-breakpoint-up(sm){
        width: 270px;
        height: 36px;
      }
      @include media-breakpoint-up(lg){
        width: 349px;
        height: 46px;
      }
    }
    .logo-text{
      @include media-breakpoint-up(lg) {
        display: none;
      }
    }
    .logo-icon{
      fill: inherit;
      transition: fill .3s ease;
    }
    &:hover{
      .logo-icon{
        fill: $brand-primary;
      }
    }
  }
  .nav-wrapper{
    z-index: 501;
    @include media-breakpoint-up(lg) {
      align-self: flex-end;
    }
  }
  .nav-item{
    margin: 0;
    width: 100%;
    text-align: left;
  }
  .nav-link{
    position: relative;
    font-family: $font-family-brand;
    font-size: 1.25rem;
    font-weight: 600;
    padding: .5rem 1rem;
    color: white;
    transition: all .7s ease;
    -webkit-font-smoothing: antialiased;
    @include media-breakpoint-up(lg){
      border-bottom: .2rem solid transparent;
      padding: .5rem 1.5rem .3rem 2rem;
      &:after{
        content: '';
        position: absolute;
        width: 0%;
        height: 100%;
        left: 0;
        top: 0;
        z-index: -1;
        background-color: white;
        transition: width .7s ease;
      }
    }
    &:hover{
      color: $brand-primary;
      &:after{
        width: 100%;
      }
    }
  }
  .donate{
    @include media-breakpoint-down(md) {
      display: none;
    }
  }

  .nav-item.active{
    .nav-link{
      color: $brand-primary;
      border-color: $brand-primary;
      @include media-breakpoint-down(md){
        background-color: white;
      }
      &:after{
        width: 100%;
      }
    }
  }

}
body:not(.homepage){
  #nav-main.nav-dark{
    @include media-breakpoint-up(lg){
      .nav-link{
        color: $brand-secondary-light;
        &:hover{
          color: $brand-primary;
        }
      }
      #logo-icon{
        .roof{
          fill: $brand-primary;
          transition: fill .7s ease;
        }
        .line{
          fill: $brand-secondary;
          transition: fill .7s ease;
        }

        &:hover{
          .logo-icon{
            fill: $brand-primary;
          }
        }
      }
    }
  }
}

body.homepage #nav-main {
  @include media-breakpoint-up(lg) {
    max-width:none;
    width: 100%;

    .nav-wrapper{
      margin-right: -1.5rem;
      margin-left: auto;
    }
    #logo-icon{
      padding-left: 2.5rem;
      .logo-text{
        display: block;
      }
      svg{
        transform: scale(2.5);
        transform-origin: top left;
      }
    }
    .nav-link{
      text-align: right;
      padding: .5rem 2rem .3rem 1.5rem;
      &:after{
        right:  0;
        left: inherit;
      }
    }
  }
}


//MOBILE MENU stuff

.navbar-toggler{
  position: absolute;
  right: 10px;
  top: 20px;
  z-index: 11;
  &:focus{
    outline: none;
  }

  #line-1, #line-3{
    transform: none;
    transition: transform .6s ease-in-out;
  }

  &:not(.collapsed){
    #line-1{
      transform: rotateZ(35deg) translateY(-9px);
    }
    #line-3{
      transform: rotateZ(-35deg) translateY(6px);
    }
  }

   & + #donate-btn{
    background-color: $brand-primary;
    color: white;
    font-size: .6rem;
    font-weight: 600;
    letter-spacing: 1.6px;
    position: absolute;
    top: 50px;
    right: 10px;
    text-transform: uppercase;
    z-index: 0;

    @include media-breakpoint-up(lg){
      display: none;
    }

    @include media-breakpoint-down(lg){
      &:hover{
        background-color: white;
        color: $brand-primary;
      }
    }
  }
}

#nav-menu{
  @include media-breakpoint-down(md){
    position: fixed;
    background-color: $tint-1;
    width: 100vw;
    height: 100vh;
    top: 0;
    left: 0;
    padding-top: 80px;
    transform: translateX(100vw);
    transition: transform .6s ease-in-out;
    display: block;

    &.show, &.collapsing{
      transform: translateX(0);
      height: 100vh !important;
      transition: transform .6s ease-in-out;
    }

  }
  svg.ext-link{
    margin-left: 5px;
    transform: translateY(3px);
  }


}

body.menu-open{
  @include media-breakpoint-down(md){
    overflow:hidden;
    #nav-main{
      #logo-icon, .navbar-toggler{
        position: fixed;
      }
    }
  }
}

#nav-utility{
  position: absolute;
  z-index: 500;
  width: 100%;
  padding-top: 1.5rem;
  #logo-text{
    position: absolute;
    left: 76px;
    top: 2.4rem;
  }

  .nav-item {
     margin-left: .3rem;
  }

  .nav-link{
    text-transform: uppercase;
    font-size: .8rem;
    letter-spacing: 1.6px;
    font-weight: 600;
    color: white;
    -webkit-font-smoothing: antialiased;
    padding: .25rem .5rem;

    display: flex;
    align-items: center;
    transition: all .3s ease;

    &:hover{
      color: $brand-primary;
      background-color: white;
      svg path{
        fill: $brand-primary;
      }
    }
  }
  .donate .nav-link{
    background-color: $brand-primary;
    &:hover{
      background-color: white;
    }
  }
}


body.homepage #nav-utility {
  position: fixed;

  #logo-text {
    display: none;

  }
}
